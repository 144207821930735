import { fetch } from '..'
import { GET, makeQueryString, POST } from '../apiHelper'
import { ORDER_DIRECTIONS } from '../constants'

export const sendInternshipMessage = (user, { internship, content }) => {
  return fetch(
    '/internship-message/send',
    POST,
    { internship, content },
    user
  )
}

export const getInternshipsMessages = (user, currentUser, params = { page: 1, pageSize: 50, unread: false, orderDirection: ORDER_DIRECTIONS.DESC }) => {
  return fetch(
    `/api/users/${currentUser.id}/internship-messages${makeQueryString(params)}`,
    GET,
    null,
    user
  )
}

export const getInternshipMessages = (user, internship) => {
  return fetch(
    `/internship-message/consult/${internship.id}`,
    GET,
    null,
    user
  )
}

export const readInternshipMessages = (user, internship) => {
  return fetch(
    '/internship-message/read',
    POST,
    { internship: internship.id },
    user
  )
}

export const unreadInternshipMessage = (user, messageId) => {
  return fetch(
    '/internship-message/unread',
    POST,
    { messageId },
    user
  )
}

export const getInternshipsMessagesCount = (user, params = { ids: [] }) => {
  return fetch(
    `/internships/internship-messages${makeQueryString(params)}`,
    GET,
    null,
    user
  )
}
