import React, { createContext, useState } from 'react'

import { connect } from 'react-redux'
import { getUser } from '../reducers/UserReducer'
import useLocalStorage from '../hooks/UseLocalStorage'
import moment from 'moment'
import LOCAL_STORAGE_KEYS from '../utils/localStorage'

const { INTERNSHIP_DATE, INTERNSHIP_SEARCH } = LOCAL_STORAGE_KEYS.GLOBAL_FILTERS

const mapStateToProps = state => ({
  user: getUser(state.getUser)
})

export const GlobalFiltersContext = createContext()

const GlobalFiltersProvider = ({ children, user }) => {
  const [selectedInstitution, setSelectedInstitution] = useState(null)
  const [internshipSearch, setInternshipSearch] = useLocalStorage(INTERNSHIP_SEARCH, '')
  const [internshipDate, setInternshipDate] = useLocalStorage(INTERNSHIP_DATE, moment().startOf('isoWeek'), true)

  return (
    <GlobalFiltersContext.Provider
      value={{ internshipDate, internshipSearch, selectedInstitution, setInternshipDate, setInternshipSearch, setSelectedInstitution }}
    >
      {children}
    </GlobalFiltersContext.Provider>
  )
}

export default connect(mapStateToProps)(GlobalFiltersProvider)
