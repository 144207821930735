import { useCallback, useEffect, useState } from 'react'

import { Metadata, retrieveMetadata } from '../utils/http'
import { getUser } from '../reducers/UserReducer'
import { useSelector } from 'react-redux'
import { debounce, isArray } from 'lodash'

const DEFAULT_DATA = { activeKey: 1, payload: {} }

const useQuery = ({ request, defaultMetadata = new Metadata() }) => {
  const user = useSelector(state => getUser(state.getUser))

  const [data, setData] = useState(DEFAULT_DATA)
  const [metadata, setMetadata] = useState(defaultMetadata)
  const [parameters, setParameters] = useState({})
  const [loading, setLoading] = useState(false)

  const wrappedRequest = useCallback((reload = false) => {
    const pagination = metadata.toQueryParameters()

    if (!isArray(data.payload[pagination.page]) || reload) {
      setLoading(true)

      const promise = request(user, { ...parameters, ...pagination })

      if (promise instanceof Promise) {
        promise.then(json => {
          if (json?.data) {
            setData({
              ...data,
              payload: { ...data.payload, [pagination.page]: json.data },
              activeKey: pagination.page
            })
          }

          if (json?.meta) {
            setMetadata(retrieveMetadata(json.meta))
          }

          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    } else {
      setData({ ...data, activeKey: pagination.page })
    }
  }, [request, parameters, metadata.page, metadata.pageSize, user.id, setMetadata, setData, setLoading])

  const refetch = useCallback(() => {
    setMetadata(new Metadata({ ...metadata }))
  }, [metadata])

  const next = useCallback(() => {
    setMetadata(new Metadata({ ...metadata, page: metadata.next }))
  }, [metadata])

  const previous = useCallback(() => {
    setMetadata(new Metadata({ ...metadata, page: metadata.previous }))
  }, [metadata])

  const first = useCallback(() => {
    setMetadata(new Metadata({ ...metadata, page: 1 }))
  }, [metadata])

  const last = useCallback(() => {
    setMetadata(new Metadata({ ...metadata, page: metadata.last }))
  }, [metadata])

  useEffect(() => {
    const fetch = debounce(wrappedRequest, 300)

    fetch()

    return () => fetch.cancel()
  }, [wrappedRequest])

  const mutate = useCallback(mutator => {
    const newData = { ...data }

    newData.payload[newData.activeKey] = mutator(newData.payload[newData.activeKey])

    setData(newData)
  }, [data, setData])

  const mutateParameters = useCallback(parameters => {
    setParameters(parameters)
    setMetadata(new Metadata({ ...metadata, page: 1 }))
    setData({ ...DEFAULT_DATA })
    setLoading(true)
  }, [setParameters, setMetadata, setData, setLoading])

  return {
    all: data.payload,
    data: (data.payload[data.activeKey] ?? []),
    isLastPage: metadata.page === metadata.last,
    metadata,
    loading,
    setMetadata,
    setParameters: mutateParameters,
    refetch,
    setData,
    next,
    previous,
    first,
    last,
    mutate
  }
}

export default useQuery
