import { getTranslate } from 'react-localize-redux'
import { getUrlParameterByName } from '../../utils'
import { useContext, useEffect, useState } from 'react'
import Loading from '../../HOC/Loading'
import { connect } from 'react-redux'
import Error500 from './Error500'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import AnonymousFormWrapper from '../../Components/shared/AnonymousFormWrapper'
import { AuthContext } from '../../Providers/AuthProvider'

const mapStateToProps = state => ({
  t: getTranslate(state.locale)
})

const AuthCallback = ({ t, onGetUserContextAndData, onAfterLogin }) => {
  const code = getUrlParameterByName('code')
  const profileId = getUrlParameterByName('profile_id')
  const [error, setError] = useState(false)

  const { authCallback, navigateToLoginPage } = useContext(AuthContext)

  useEffect(() => {
    if (code && profileId) authCallback(code, onGetUserContextAndData, onAfterLogin).catch((e) => setError(true))
  }, [code, profileId, authCallback])

  if (!code || !profileId || error) {
    console.error('Missing authorization code')

    return (
      <AnonymousFormWrapper
        noBgImg
      >
        <Error500
          extra={
            <Button type='primary' icon={<FontAwesomeIcon icon={faLock} />} onClick={() => navigateToLoginPage()}>
              {t('auth.go_to_login_page')}
            </Button>
          }
        />
      </AnonymousFormWrapper>
    )
  }

  return (
    <AnonymousFormWrapper
      noBgImg
    >
      <Loading
        loading
        size='5x'
      />
    </AnonymousFormWrapper>
  )
}
export default connect(mapStateToProps)(AuthCallback)
