const INTERNSHIPS_MANAGER_ROOT = 'internships-manager-view'
const GLOBAL_FILTERS_ROOT = 'global-filters'

const LOCAL_STORAGE_KEYS = {
  INTERNSHIPS_MANAGER: {
    ROOT: INTERNSHIPS_MANAGER_ROOT,
    SEARCH: INTERNSHIPS_MANAGER_ROOT + '.search',
    STATES: INTERNSHIPS_MANAGER_ROOT + '.states',
    SCHOOLS: INTERNSHIPS_MANAGER_ROOT + '.schools',
    INSTITUTIONS: INTERNSHIPS_MANAGER_ROOT + '.institutions',
    ARE_STARTED_EXCLUDED: INTERNSHIPS_MANAGER_ROOT + '.are-started-excluded',
    SECTIONS: INTERNSHIPS_MANAGER_ROOT + '.sections',
    ORDER_BY: INTERNSHIPS_MANAGER_ROOT + '.order-by',
    SORT_DIRECTION: INTERNSHIPS_MANAGER_ROOT + '.sort-direction',
    PRECISION: INTERNSHIPS_MANAGER_ROOT + '.precision',
    PARAMETERS: INTERNSHIPS_MANAGER_ROOT + '.parameters'
  },
  GLOBAL_FILTERS: {
    ROOT: GLOBAL_FILTERS_ROOT,
    INTERNSHIP_SEARCH: GLOBAL_FILTERS_ROOT + '.search',
    INTERNSHIP_DATE: GLOBAL_FILTERS_ROOT + '.date'
  }
}

export const LOCAL_STORAGE_KEYS_TO_REMOVE_ON_LOGOUT = [
  LOCAL_STORAGE_KEYS.INTERNSHIPS_MANAGER.SCHOOLS,
  LOCAL_STORAGE_KEYS.INTERNSHIPS_MANAGER.INSTITUTIONS,
  LOCAL_STORAGE_KEYS.INTERNSHIPS_MANAGER.SECTIONS
]

export default LOCAL_STORAGE_KEYS
