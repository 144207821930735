import React, { Component, useContext } from 'react'

import InformationDisplay from '../shared/InformationDisplay'
import Linkify from 'react-linkify'
import moment from 'moment'
import { Button, Drawer, notification } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark, faCar, faPeopleArrows, faRoute } from '@fortawesome/free-solid-svg-icons'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { requestMultipart, downloadFile, generalErrorHandler, requestWithPromise } from '../../utils'
import EyeBan from '../shared/EyeBan'
import { getSectorDocuments } from '../../utils/api/sector'
import { CARPOOL_OPTIN_TEXTS, CARPOOL_TYPE } from '../../utils/entities/optin'
import CarpoolingDrawer from './CarpoolingDrawer'
import UserCarpoolModal from '../shared/UserCarpoolModal'
import { faSmile } from '@fortawesome/free-regular-svg-icons'
import StudentFeedback from './StudentFeedback'
import { EvaluationsContext } from '../../Providers/EvaluationsProvider'
import { SCHOOL_OPTIONS, internshipStates } from '../../utils/constants'
import { GlobalContext } from '../../Providers/GlobalProvider'
import ResizeDrawer from '../../HOC/ResizeDrawer'
import { getInstitutionDocuments } from '../../utils/api/institution'
import { SCHEDULE_VALIDATED } from '../../utils/entities/internship'
import { isObject } from 'lodash'
import { getTimezoneLessMoment } from '../../utils/momentjs'
import { TYPES } from '../../utils/entities/internshipDocument'
import { InternshipDocumentsDrawerContext } from '../../Providers/Drawer/InternshipDocumentsDrawer'

const { INTERNSHIPS_CARPOOLING } = SCHOOL_OPTIONS

const ACCEPTED_DOCUMENT_TYPES = [
  'text/csv', '.csv', '.xlsx',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

class InternshipItemImpl extends Component {
  constructor (props) {
    super(props)

    const { internship } = props
    // check if we have the file details
    this.validationDocumentFileDetails = null
    if (internship.studentValidationDocumentId) {
      // student already uploaded a document, we have to download it
      const fileDetails = internship.documents.find(item => item.id === internship.studentValidationDocumentId)
      if (fileDetails) {
        this.validationDocumentFileDetails = fileDetails
      }
    }

    this.state = {
      uploading: false,
      loadingInstitutionFiles: false,
      loadingSectorFiles: false,
      files: [],
      internshipFilesManagerOpen: false,
      loadingContactPersons: true,
      contactPersons: [],
      contactPersonsSector: [],
      contactPersonsSection: [],
      conventionFileName: '',
      visibleCarpoolDrawer: false,
      carpoolModalVisible: false,
      internshipFeedback: null
    }
  }

  componentDidMount () {
    this.updateInternshipFiles()
    this.loadContactPersons()

    this.renderNameUploadedFile(this.props.internship.documents.filter(d => d.type === TYPES.CONVENTION)[0] ?? null)

    CARPOOL_OPTIN_TEXTS.forEach(t => {
      if (this.props.optins.find(optin => optin.text === t)) {
        this.setState({ optinCarpool: true })
      }
    })
  }

  updateInternshipFiles = () => {
    this.setState({ loadingInstitutionFiles: true })

    getInstitutionDocuments(this.props.getUser, this.props.internship.institution).then(json => {
      if (json?.data) {
        let files = this.state.files
        files = files.concat(json.data)
        this.setState({
          loadingInstitutionFiles: false,
          files: files
        })
      }
    })

    getSectorDocuments(this.props.getUser, this.props.internship.sector)
      .then(json => {
        let files = this.state.files
        files = files.concat(json.data)
        this.setState({
          loadingSectorFiles: false,
          files: files
        })
      })
  }

  loadContactPersons = async () => {
    const { internship: { institution, school, sector, section }, getUser } = this.props
    let data = null
    let dataSector = null
    let dataSection = null

    try {
      if (institution !== null && sector !== null && school !== null && section !== null) {
        data = await requestWithPromise(`/contact-persons/${institution.id}/${sector.id}/${school.id}/${section.id}`, 'GET', null, getUser)
      }

      if (sector !== null) {
        dataSector = await requestWithPromise(`/api/sectors/${sector.id}/contact-persons`, 'GET', null, getUser)
      }

      if (section !== null) {
        dataSection = await requestWithPromise(`/api/school-sections/${section.id}/contact-persons`, 'GET', null, getUser)
      }

      if (data !== null || dataSector !== null || dataSection != null) {
        this.setState({ contactPersons: data === null ? [] : data.data, contactPersonsSector: dataSector === null ? [] : dataSector.data, contactPersonsSection: dataSection === null ? [] : dataSection.data, loadingContactPersons: false })
      } else {
        this.setState({ contactPersons: [], loadingContactPersons: false })
      }
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  handleClickOnConventionInput = () => {
    this.refs.documentInput.click()
  }

  handleDocumentUpload = (e) => {
    this.setState({ uploading: true })

    const file = e.target.files[0]
    if (file && ACCEPTED_DOCUMENT_TYPES.indexOf(file.type) > -1) {
      const body = {
        'document[internship]': this.props.id,
        file: file,
        type: TYPES.CONVENTION
      }

      requestMultipart('/internship/document/upload', 'POST', body, this.props.getUser)
        .then(response => {
          const document = response.data
          const internship = this.props.internship
          const documents = internship.documents
          documents.push(document)
          internship.documents = documents

          this.renderNameUploadedFile(document)

          notification.success({ message: 'Convention de stage envoyée !', placement: 'bottomRight' })
          this.setState({ uploading: false })
          this.props.updateInternship(internship)
        })
    } else {
      notification.error({ message: this.props.t('Only PDF, Word, Excel and CSV file types are supported.'), placement: 'bottomRight' })
    }
  }

  downloadInternshipConvention = (document) => {
    try {
      downloadFile('/internship/document/download/' + document.id, document.originalName, this.props.getUser)
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  downloadInternshipFile = (fileId) => {
    const file = this.state.files.filter(f => f.id === fileId)[0]
    let fileType = null
    if (typeof file.institution !== 'undefined') fileType = 'institution'
    if (typeof file.sector !== 'undefined') fileType = 'sector'
    if (fileType === null) {
      notification.error({ message: this.props.t('This document is not downloadable.'), placement: 'bottomRight' })
      return
    }
    try {
      downloadFile(`/${fileType === 'institution' ? 'institution' : 'sector'}/document/download/${fileId}`, file.originalName, this.props.getUser)
    } catch (err) {
      generalErrorHandler(err)
    }
  }

  renderInternshipFiles = (fileType, showSectionFiles = false) => {
    if (this.state.loadingInstitutionFiles) return this.props.t('misc.loading')
    let files = this.state.files
    files = files.filter(f => {
      return typeof f[fileType] !== 'undefined'
    })

    if (fileType === 'sector') {
      if (!showSectionFiles) {
        files = files.filter(f => f.isSectionFile === false)
      } else {
        files = files.filter(f =>
          f.isSectionFile === true &&
          f.sections && f.sections.length &&
          f.sections.find(section => section.id === this.props.internship.section.id) !== undefined)
      }
    }

    if (files.length === 0) return this.props.t('None')

    return files.map(f => {
      return <a style={{ marginBottom: '10px' }} key={f.id} onClick={() => this.downloadInternshipFile(f.id)}>{f.originalName}</a>
    })
  }

  renderInternshipConvention = () => {
    const document = this.props.internship.documents
      .sort((d1, d2) => moment(d1.updatedAt.date).isSameOrAfter(moment(d2.updatedAt.date)) ? -1 : 1)
      .filter(d => d.type === TYPES.CONVENTION)[0]

    if (typeof document !== 'undefined') {
      let updateButton = <div>{this.props.t('Your internship convention has been accepted.')}</div>
      if (!document.locked) {
        updateButton = (
          <Button type='primary' size='small' onClick={this.handleClickOnConventionInput} loading={this.state.uploading}>
            <FontAwesomeIcon icon='redo' />&nbsp;{this.props.t('Update')}
          </Button>
        )
      }

      return (
        <div className='flex-row'>
          <Button type='default' size='small' onClick={() => this.downloadInternshipConvention(document)}>
            <FontAwesomeIcon icon='download' />&nbsp;{this.props.t('Download')}
          </Button>
          <div className='h-spacing' />
          {updateButton}
        </div>
      )
    }

    return (
      <Button size='small' type='primary' onClick={this.handleClickOnConventionInput} loading={this.state.uploading}>
        <FontAwesomeIcon icon='plus' />&nbsp;{this.props.t('Add')}
      </Button>
    )
  }

  renderComments = () => {
    let comments = this.props.t('None')
    const internship = this.props.internship

    if ((internship.section !== null && internship.section.comment !== null && internship.section.comment.length > 0) ||
      (internship.sector !== null && internship.sector.comment !== null && internship.sector.comment.length > 0)) {
      comments = (
        <div className='flex-column'>
          <div>{internship.section?.comment}</div>
          <div>{internship.sector?.comment}</div>
        </div>
      )
    }

    return comments
  }

  renderContactPersons = () => {
    const { t } = this.props
    const { loadingContactPersons, contactPersons, contactPersonsSector, contactPersonsSection } = this.state

    if (loadingContactPersons) {
      return (
        <div className='flex-column'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className='loading-icon black' />
            <div style={{ marginLeft: '4px', marginTop: '4px' }}>{t('Loading contact persons')}</div>
          </div>
        </div>
      )
    }

    if (!contactPersons.length && !contactPersonsSector.length && !contactPersonsSection.length) {
      return (
        <div className='flex-column'>
          {t('None')}
        </div>
      )
    }

    const schoolContactPersons = contactPersons.filter(item => item.type === 'school').concat(contactPersonsSection)
    const institutionContactPersons = contactPersons.filter(item => item.type === 'institution').concat(contactPersonsSector)

    return (
      <div className='flex-column'>
        {schoolContactPersons.length > 0 && schoolContactPersons.map(cp => {
          return (
            <div key={cp.email}>
              <b> {t('School')}: </b> &nbsp; {cp.lastname && cp.firstname ? `${cp.lastname} ${cp.firstname} - ` : (cp.name ?? '')}
              {cp.email && ' - '}
              {cp.email && (
                <a href={'mailto:' + cp.email}>
                  {cp.email}
                </a>
              )}
              {cp.phone ? ` - ${cp.phone}` : ''}
            </div>
          )
        })}
        {institutionContactPersons.length > 0 && institutionContactPersons.map(cp => {
          return (
            <div key={cp.email}>
              <b> {t('Institution')}: </b> &nbsp; {cp.lastname && cp.firstname ? `${cp.lastname} ${cp.firstname}` : (cp.name ?? '')}
              {cp.email && ' - '}
              {cp.email && (
                <a href={'mailto:' + cp.email}>
                  {cp.email}
                </a>
              )}
              {cp.phone ? ` - ${cp.phone}` : ''}
            </div>
          )
        })}
      </div>
    )
  }

  renderStudentFreeFields = (listFreeFields, institutionId) => {
    const { t, getActiveLanguage, studentMode } = this.props

    const studentFreeFields = []
    if (studentMode && typeof listFreeFields[institutionId] !== 'undefined') {
      listFreeFields[institutionId].forEach((sff, index) => {
        const typeLabel = sff.fieldType.fieldTypelabels.find(ftl => ftl.language.languageLocales.find(llc => llc.languageTag === getActiveLanguage))
        const label = isObject(typeLabel) ? typeLabel.label : t('Without label')

        studentFreeFields.push(
          <div className='flex-column' key={label + index}>
            <span style={{ display: 'inlineBlock' }}>
              <span style={{ paddingRight: '10px' }}>{label}:</span>

              {sff.fieldType.isEncryption
                ? (
                  <EyeBan
                    value={sff.valueDecrypt}
                    hidden={sff.fieldType.isEncryption}
                  />
                )
                : sff.fieldValue}
            </span>
          </div>
        )
      })
    } else if (typeof listFreeFields[institutionId] !== 'undefined') {
      listFreeFields[institutionId].forEach(sff => {
        if (sff[getActiveLanguage].isStudentDisplayed) {
          studentFreeFields.push(
            <div className='flex-column' key={sff[getActiveLanguage].label}>
              <span style={{ display: 'inlineBlock' }}>
                <span style={{ paddingRight: '10px' }}>{sff[getActiveLanguage].label}:</span>

                {sff[getActiveLanguage].isEncryption
                  ? (
                    <EyeBan
                      value={sff[getActiveLanguage].valueDecrypt}
                      hidden={sff[getActiveLanguage].isEncryption}
                    />
                  )
                  : sff[getActiveLanguage].value}
              </span>
            </div>
          )
        }
      })
    }

    if (typeof listFreeFields[institutionId] === 'undefined' || studentFreeFields.length === 0) {
      return (
        <div className='flex-column'>
          {t('None')}
        </div>
      )
    }

    return studentFreeFields
  }

  renderInstitutionDescription = () => {
    let description = this.props.t('None')
    const internship = this.props.internship
    if (internship.institution.description !== null && internship.institution.description.length > 0) {
      description = (
        <div>
          <Linkify
            componentDecorator={(href, text, key) => {
              return (
                <a href={href} key={key} target='_blank' rel='noopener noreferrer'>
                  {this.props.t('Click here')}.<br />
                </a>
              )
            }}
          >
            {internship.institution.description}
          </Linkify>
        </div>
      )
    }

    return description
  }

  renderNameUploadedFile = (document) => {
    if (document) {
      const date = moment(document.updatedAt.date)
      date.add(date.utcOffset(), 'minutes')

      this.setState({
        conventionFileName:
          document.originalName +
          ', ' +
         date.format('DD-MM-YYYY, HH:mm')
      })
    } else {
      this.setState({ conventionFileName: this.props.t('None') })
    }
  }

  render () {
    const { t, internship } = this.props
    const sector = internship.sector.name
    let coordinator = internship.coordinator
    let route = internship.sector.emplacement

    if (!coordinator || coordinator === '') {
      coordinator = <p className='ant-alert-error'>{t('None')}</p>
    } else {
      coordinator = <a href={'mailto:' + internship.coordinator.email}>{internship.coordinator.email}</a>
    }

    if (!route || route === '') {
      route = t('None')
    }

    let internshipInfo = [
      { id: 1, icon: 'medkit', title: t('Location'), caption: sector },
      { id: 11, icon: faRoute, title: t('Road'), caption: route },
      { id: 2, icon: 'user-tie', title: t('Coordinator'), caption: coordinator },
      { id: 10, icon: faPeopleArrows, title: t('Contact persons'), caption: this.renderContactPersons() },
      { id: 3, icon: 'comment', title: t('Comments'), caption: this.renderComments() },
      { id: 4, icon: 'info-circle', title: t('Information about institution'), caption: this.renderInstitutionDescription() },
      { id: 5, icon: 'file-alt', title: t('Files attached to the institution'), caption: this.renderInternshipFiles('institution') },
      { id: 6, icon: 'file-alt', title: t('Files attached to the care unit'), caption: this.renderInternshipFiles('sector') },
      { id: 12, icon: 'file-alt', title: `${t('Files attached to the care unit for:')} ${internship.school.name}`, caption: this.renderInternshipFiles('sector', true) },
      { id: 13, icon: faBookmark, title: t('Free fields encode by institution'), caption: this.renderStudentFreeFields(this.props.studentFreeFields, internship.institution.id) },
      {
        id: 7,
        icon: 'file-alt',
        title: t('Internship convention'),
        caption:
        [
          <div style={{ color: 'grey', fontSize: '90%', padding: '3px', fontStyle: 'italic' }} key={0}>{this.state.conventionFileName}</div>,
          <div key={1}>{this.renderInternshipConvention()}</div>
        ]
      },
      {
        id: 8,
        icon: 'calendar-plus',
        title: internship.section && internship.section.actsMandatory ? t('Schedule and acts') : t('Schedule'),
        caption: (
          <div className='schedule-button-div'>
            <Button size='small' type='primary' onClick={() => this.props.onInternshipSelect(this.props.id)}>
              <FontAwesomeIcon icon='calendar-plus' />&nbsp;{t('See / edit')}
            </Button>
          </div>
        )
      },
      {
        id: 9,
        icon: 'file-alt',
        title: t('Internship files'),
        caption: (
          <div className='internship-files-button-div'>
            <Button
              size='small'
              type='primary'
              onClick={() => this.props.setSelectedInternship(
                { data: internship, readOnly: internship.studentValidationDocumentId !== null || internship.state === SCHEDULE_VALIDATED }
              )}
            >
              <FontAwesomeIcon icon='paperclip' />&nbsp;{t('See / attach')}
            </Button>
          </div>
        )
      }
    ]

    const carpoolOptin = this.props.optins.find(optin => optin.type === CARPOOL_TYPE)
    const carpoolEnable = this.props.schoolOptions[internship.school.id] && this.props.schoolOptions[internship.school.id][INTERNSHIPS_CARPOOLING]

    if (carpoolEnable && getTimezoneLessMoment(internship.endDate) > moment() && (!carpoolOptin || carpoolOptin.accepted) && internship.state !== SCHEDULE_VALIDATED) {
      internshipInfo = internshipInfo.concat({
        id: 14,
        icon: faCar,
        title: t('Other students at the same internship place'),
        caption: (
          <Button
            size='small'
            type='primary'
            style={{ width: 'fit-content' }}
            onClick={() => this.setState(this.state.optinCarpool ? { visibleCarpoolDrawer: true } : { carpoolModalVisible: true })}
          >
            <FontAwesomeIcon icon={faCar} />&nbsp;{t('Possible carpools')}
          </Button>
        )
      })
    }

    if (
      this.props.attractivityStateByInstitution[internship.institution.id] &&
      internship.state === internshipStates.SCHEDULE_VALIDATED
    ) {
      internshipInfo = internshipInfo.concat({
        id: 15,
        icon: faSmile,
        title: 'Feedback',
        caption: (
          <div>
            <Button size='small' type='primary' onClick={() => this.setState({ internshipFeedback: internship })}>
              <FontAwesomeIcon icon={faSmile} />&nbsp;{t('Giving feedback')}
            </Button>
          </div>
        )
      })
    }

    return (
      <div>
        <div>
          <InformationDisplay information={internshipInfo} />
          <input type='file' ref='documentInput' onChange={this.handleDocumentUpload} accept={ACCEPTED_DOCUMENT_TYPES.join(',')} style={{ display: 'none' }} />
        </div>
        <ResizeDrawer visible={this.state.internshipFeedback !== null}>
          <Drawer
            title={t('Internship feedback')}
            onClose={() => this.setState({ internshipFeedback: null })}
          >
            <StudentFeedback
              internship={this.state.internshipFeedback}
              onSave={() => {
                if (this.props.internshipsToEvaluate.find(i => i.id === internship.id)) {
                  this.props.setInternshipsToEvaluate(this.props.internshipsToEvaluate.filter(i => i.id !== internship.id))
                }
              }}
            />
          </Drawer>
        </ResizeDrawer>
        <CarpoolingDrawer
          internship={internship}
          visible={this.state.visibleCarpoolDrawer}
          onClose={() => this.setState({ visibleCarpoolDrawer: false })}
          width='640px'
        />
        <UserCarpoolModal
          visible={this.state.carpoolModalVisible}
          onCancel={() => this.setState({ carpoolModalVisible: false })}
          onSubmit={error => {
            this.setState({ visibleCarpoolDrawer: !error, carpoolModalVisible: false, optinCarpool: true })
          }}
        />
      </div>
    )
  }
}

// This is a temporary solution on waiting to convert this component into a stateless component
function InternshipItem (props) {
  const { attractivityStateByInstitution, setInternshipsToEvaluate, internshipsToEvaluate } = useContext(EvaluationsContext)
  const { optins } = useContext(GlobalContext)
  const { setSelectedInternship } = useContext(InternshipDocumentsDrawerContext)
  const extendedProps = { ...props, attractivityStateByInstitution, setInternshipsToEvaluate, internshipsToEvaluate, optins, setSelectedInternship }

  return (<InternshipItemImpl {...extendedProps} />)
};

export default connect(mapStateToProps, mapDispatchToProps)(InternshipItem)
