import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import React from 'react'

const TooltipIconButton = ({ icon, iconColor, overlayStyle = {}, title, onClick = () => {} }) => {
  return (
    <Tooltip title={title} overlayStyle={overlayStyle}>
      <Button onClick={onClick}>
        <FontAwesomeIcon icon={icon} color={iconColor} />
      </Button>
    </Tooltip>
  )
}

export default TooltipIconButton
