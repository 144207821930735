import React, { createContext, useCallback, useState } from 'react'

import { Modal } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { onError, onSuccess } from '../../utils/apiHelper'
import { getUser } from '../../reducers/UserReducer'
import InstitutionDocumentForm from '../../Components/shared/Forms/InstitutionDocumentForm'
import { uploadInstitutionDocument } from '../../utils/api/institution'

export const InstitutionDocumentModalContext = createContext()

const DEFAULT_FILE = { data: null, callback: null }

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InstitutionDocumentModal = ({ children, user, t }) => {
  const [selectedFile, setSelectedFile] = useState(DEFAULT_FILE)

  const handleSubmit = useCallback(({ data, file }) => {
    uploadInstitutionDocument(user, selectedFile.data.institution, file).then(json => {
      if (json?.data) {
        onSuccess(t('institution_document.create.success'))

        if (selectedFile.callback) {
          selectedFile.callback(json.data)
        }
      }
    }).catch(() => {
      onError(t('institution_document.create.error'))
    }).finally(() => {
      setSelectedFile(DEFAULT_FILE)
    })
  }, [selectedFile, setSelectedFile, user, t])

  return (
    <>
      <InstitutionDocumentModalContext.Provider value={{ setSelectedFile }}>
        {children}
      </InstitutionDocumentModalContext.Provider>
      <Modal
        width={500}
        title={t('institution_document_modal.title')}
        visible={!!selectedFile.data}
        onCancel={() => setSelectedFile(DEFAULT_FILE)}
        footer={null}
        destroyOnClose
        zIndex={5000}
      >
        <InstitutionDocumentForm
          item={selectedFile.data}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(InstitutionDocumentModal)
