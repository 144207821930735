import React, { useContext, useState, useCallback, useEffect, useMemo } from 'react'

import ChatWidget, { MESSAGE_RECEIVED, MESSAGE_SENT } from './ChatWidget'
import moment from 'moment'
import { DATE_WITHOUT_TIME, SCHOOL_CONTEXT } from '../../utils/constants'
import { getInternshipMessages, sendInternshipMessage } from '../../utils/api/internshipMessage'
import { displayError } from '../../utils/apiHelper'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { isString, isNull } from 'lodash'
import { isObserver } from '../../utils/roles'
import { isObject } from '../../utils'
import IconLabel, { FONT_SIZES } from '../icons/IconLabel'
import { faClinicMedical, faStethoscope, faUserGraduate } from '@fortawesome/free-solid-svg-icons'
import { COLORS_PALETTE } from '../../config/colors'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { GlobalFiltersContext } from '../../Providers/GlobalFiltersProvider'

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const ChatManager = ({ internship, user, onClose, t }) => {
  const { setInternshipDate, setInternshipSearch } = useContext(GlobalFiltersContext)

  const [messages, setMessages] = useState([])

  const messageToBeUnread = useMemo(() => {
    return messages.length > 0 && messages[messages.length - 1].origin && messages[messages.length - 1].origin !== MESSAGE_SENT ? messages[messages.length - 1] : null
  }, [messages, user.id])

  const studentName = useMemo(() => {
    let name = ''

    if (internship && internship.studentName) {
      name = internship.studentName
    } else if (internship && internship.student) {
      name = isObject(internship.student) ? `${internship.student.lastname} ${internship.student.firstname}` : `${internship.lastname} ${internship.firstname}`
    }

    return name
  }, [internship])

  const fetchMessages = useCallback(() => {
    if (internship) {
      getInternshipMessages(user, internship).then(json => {
        if (json.data) {
          const lastItem = messages.length === 0 ? null : messages[messages.length - 1]
          const lastNewItem = json.data.length === 0 ? null : json.data[json.data.length - 1]

          if (
            (isNull(lastItem) && !isNull(lastNewItem)) ||
            (!isNull(lastItem) && isNull(lastNewItem)) ||
            (!isNull(lastItem) && !isNull(lastNewItem) && lastItem.id !== lastNewItem.id)
          ) {
            setMessages(json.data.map(m => {
              m.origin = user.username === m.from.username ? MESSAGE_SENT : MESSAGE_RECEIVED

              return m
            }))
          }
        }
      })
    }
  }, [user.id, internship?.id, messages, setMessages])

  useEffect(() => {
    fetchMessages()
    const interval = setInterval(fetchMessages, 5000)

    if (internship === null) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [fetchMessages, internship])

  const postMessage = message => {
    sendInternshipMessage(user, { internship: internship.id, content: message }).then(json => {
      const message = json.data

      message.from = { username: user.username }

      setMessages([...messages, message])
    }).catch(error => {
      displayError(error, 'internship_message.send')
    })
  }

  const getDate = date => {
    if (isString(date)) {
      if (date.includes('/')) {
        return date
      }

      if (date.includes('+')) {
        date = date.split('+')[0]
      }
    }

    const formatedDate = moment(date).format('DD/MM/YY')

    if (formatedDate === 'Invalid date') {
      return date
    }

    return formatedDate
  }

  const handleCalendarChange = () => {
    let date = moment().format(DATE_WITHOUT_TIME)

    if (internship.startDate.includes('T')) {
      const dateParts = internship.startDate.split('T')
      date = moment(dateParts[0])
    } else if (internship.startDate.includes('/')) {
      const dateParts = internship.startDate.split('/')
      date = moment(`20${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`)
    }

    setInternshipSearch(studentName)
    setInternshipDate(date)
  }

  const widgetTitle = useMemo(() => {
    let name = t('Without student')
    let sectorName = t('Without sector')

    if (studentName) {
      name = studentName
    }

    if (isObject(internship)) {
      if (internship.sector) {
        sectorName = isObject(internship.sector) ? internship.sector.name : internship.sector
      }
    }

    return (
      <div className='flex-column'>
        <IconLabel icon={faUserGraduate} text={name} color={COLORS_PALETTE.WHITE} fontSize={FONT_SIZES.SMALL} padding={null} />
        <IconLabel icon={faStethoscope} text={sectorName} color={COLORS_PALETTE.WHITE} fontSize={FONT_SIZES.SMALL} padding={null} />
      </div>
    )
  }, [internship, studentName, t])

  const widgetSubtitle = useMemo(() => {
    const isSchoolContext = user.context === SCHOOL_CONTEXT

    let period = t('Without period')
    let establishmentName = t('Without establishment')

    if (internship) {
      period = `${getDate(internship?.startDate)} - ${getDate(internship?.endDate)}`

      if (isSchoolContext) {
        if (isObject(internship.institution)) {
          establishmentName = `${t('Contact')} ${internship.institution.name}`
        } else if (internship.institutionName) {
          establishmentName = `${t('Contact')} ${internship.institutionName}`
        } else if (internship.institution) {
          establishmentName = `${t('Contact')} ${internship.institution}`
        } else {
          establishmentName = t('Without institution')
        }
      } else {
        if (isObject(internship.school)) {
          establishmentName = `${t('Contact')} ${internship.school.name}`
        } else if (internship.schoolName) {
          establishmentName = `${t('Contact')} ${internship.schoolName}`
        } else if (internship.school) {
          establishmentName = `${t('Contact')} ${internship.school}`
        } else {
          establishmentName = t('Without school')
        }
      }
    }

    return (
      <div className='flex-column'>
        <IconLabel icon={faClinicMedical} text={establishmentName} color={COLORS_PALETTE.WHITE} fontSize={FONT_SIZES.SMALL} padding={null} />
        <IconLabel icon={faCalendar} text={period} color={COLORS_PALETTE.WHITE} fontSize={FONT_SIZES.SMALL} padding={null} />
      </div>
    )
  }, [user.context, internship, t])

  return (
    <>
      {internship && (
        <ChatWidget
          hasUnreadMessages={!!messageToBeUnread}
          messages={messages}
          readOnly={isObserver(user)}
          startDate={internship.startDate}
          subtitle={widgetSubtitle}
          title={widgetTitle}
          onClose={() => onClose(null)}
          onFind={handleCalendarChange}
          onSendMessage={postMessage}
          onUnread={() => onClose(messageToBeUnread)}
        />
      )}
    </>
  )
}

export default connect(mapStateToProps)(ChatManager)
