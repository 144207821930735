import React, { useContext, useEffect } from 'react'

import { Tabs } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'
import useSessionStorage from '../../../hooks/UseSessionStorage'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { InstitutionContext } from '../../../Providers/InstitutionProvider'
import SectorsTilesView from './SectorTiles/SectorsTilesView'
import SectorsListView from './SectorsList/SectorsListView'
import HospitalDepartmentsView from './HospitalDepartmentsList/HospitalDepartmentsView'
import { getUser } from '../../../reducers/UserReducer'
import { isNurse, isObserver } from '../../../utils/roles'

const { TabPane } = Tabs

const TAB_KEY = 'institution.internships_view_sectors_active_key'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const SectorsView = ({ user, displayManagePresets, t }) => {
  const { loadActTypes } = useContext(InstitutionContext)

  const [activeKey, setActiveKey] = useSessionStorage(TAB_KEY, '1')
  const isNurseOrObserver = isNurse(user) || isObserver(user)

  useEffect(() => loadActTypes(), [loadActTypes])

  return (
    <Tabs activeKey={activeKey} animated={false} onChange={setActiveKey}>
      <TabPane tab={<div><FontAwesomeIcon icon={faList} /> <span>{t('list_view')}</span></div>} key='1'>
        <SectorsListView displayManagePresets={displayManagePresets} />
      </TabPane>
      {false && (
        <TabPane tab={<div><FontAwesomeIcon icon={faList} /> <span>{t('tile_view')}</span></div>} key='2'>
          <SectorsTilesView />
        </TabPane>
      )}
      {!isNurseOrObserver && (
        <TabPane tab={<div><FontAwesomeIcon icon={faList} /> <span>{t('hospital_departments_view')}</span></div>} key='3'>
          <HospitalDepartmentsView />
        </TabPane>
      )}
    </Tabs>
  )
}

export default connect(mapStateToProps)(SectorsView)
