export const getOriginalName = file => file.originalName
export const getExtension = file => file.extension

export class InstitutionDocument {
  constructor ({ id = -1, originalName = null, mimeType = null, extension = null, size = null, institution = null } = {}) {
    this.id = id
    this.originalName = originalName
    this.mimeType = mimeType
    this.extension = extension
    this.size = size
    this.institution = institution
  }

  toRequestBody = () => {
    const body = { ...this }

    body.institution = body.institution === null ? null : body.institution.id

    return body
  }
}
