import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react'

import { Drawer } from 'antd'
import { isObject } from '../../utils'
import UserSectorTable, { DATA_TYPE_SELECT, DATA_TYPE_TAG } from './UserSectorTable'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import Loading from '../../HOC/Loading'
import { getTutoredSectors, linkTutoredSectors, unLinkTutoredSectors } from '../../utils/api/user'
import { InstitutionContext } from '../../Providers/InstitutionProvider'
import { resolvePromises } from '../../utils/apiHelper'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { isHospitalAdmin } from '../../utils/roles'

const mapStateToProps = state => ({
  user: getUser(state.getUser),
  t: getTranslate(state.locale)
})

const UserTutoredSectors = ({ user, selectedUser, onClose, readOnly, t }) => {
  const { sectors, archivedSectors } = useContext(InstitutionContext)
  const [tutoredSectors, setTutoredSectors] = useState([])

  const [loading, setLoading] = useState(false)

  const buildedSectors = useMemo(() => {
    return tutoredSectors.map(s => {
      return {
        id: s.id,
        sector: s.id,
        name: s.name,
        archived: s.archived
      }
    }).filter(s => !s.archived || archivedSectors.some(si => si.id === s.id))
  }, [tutoredSectors, sectors])

  const fetch = useCallback(targetUser => {
    setLoading(true)

    getTutoredSectors(user, targetUser).then(json => {
      if (json?.data) {
        setTutoredSectors(json.data)
      }

      setLoading(false)
    })
  }, [user, setLoading, setTutoredSectors])

  useEffect(() => {
    if (selectedUser !== null) {
      fetch(selectedUser)
    } else {
      setTutoredSectors([])
    }
  }, [selectedUser, setTutoredSectors, fetch])

  const columns = useMemo(() => [
    {
      type: DATA_TYPE_SELECT,
      name: t('Sector'),
      options: sectors
    },
    {
      type: DATA_TYPE_TAG,
      name: t('Status')
    }
  ], [t, sectors])

  const handleDelete = useCallback(data => {
    unLinkTutoredSectors(user, selectedUser, [data.id]).then(json => {
      if (json?.data) {
        fetch(selectedUser)
      }
    })
  }, [fetch, selectedUser, user])

  const handleAdd = data => {
    linkTutoredSectors(user, selectedUser, [data.newId]).then(json => {
      if (json?.data) {
        if (json?.data) {
          fetch(selectedUser)
        }
      }
    })
  }

  const handleEdit = data => {
    const promisesStack = []

    promisesStack.push(unLinkTutoredSectors(user, selectedUser, [data.prevId]))
    promisesStack.push(linkTutoredSectors(user, selectedUser, [data.newId]))

    resolvePromises(promisesStack).then(() => fetch(selectedUser))
  }

  const additionalActions = useMemo(() => {
    const actions = []

    if (isHospitalAdmin(user)) {
      actions.push({
        icon: faTrash,
        type: 'danger',
        title: 'Delete',
        titlePopconfirm: t('Delete this sector?'),
        handleOnClick: handleDelete
      })
    }

    return actions
  }, [t, user.roles, handleDelete])

  return (
    <Drawer
      title={isObject(selectedUser) ? t('sectors_tutored_by') + ' ' + selectedUser.email : '?'}
      width='640px'
      onClose={onClose}
      visible={selectedUser !== null}
    >
      <Loading loading={loading}>
        <UserSectorTable
          additionalActions={additionalActions}
          disableSelectAll
          readOnly={readOnly}
          onDataAdd={handleAdd}
          onDataEdit={handleEdit}
          data={buildedSectors}
          columns={columns}
          user={selectedUser}
        />
      </Loading>
    </Drawer>
  )
}

export default connect(mapStateToProps)(UserTutoredSectors)
