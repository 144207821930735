import React, { useState, useEffect } from 'react'

import { connect, mapStateToProps, mapDispatchToProps } from '../../../reducers/Dispatchers'
import { canOnlyEditEventCode, createShiftOrConfigureIt, getShiftByDayAndInternship } from './helper'
import { getMultiSelectionShiftEncapsulation } from '../../../utils/entities/ShiftEncapsulation'
import { MULTI_SELECT_MODE } from './constants'
import moment from 'moment'
import SimpleHeaderCell from './tableCells/SimpleHeaderCell'
import PausesHeaderCell from './tableCells/PausesHeaderCell'
import TotalHeaderCell from './tableCells/TotalHeaderCell'
import Line from './ShiftTableParts/Line'
import { fetchSchoolOptions } from '../../../utils/api/school'
import { LANGUAGE_TAG_EN, ROLE_SUPERVISOR, SCHOOL_OPTIONS } from '../../../utils/constants'
import { isObject } from '../../../utils'
import { Checkbox } from 'antd'

const { SUPERVISORS_EDIT_SCHEDULE } = SCHOOL_OPTIONS

const DATE_FORMAT_TYPE = 'DD/MM'
const { COLUMN } = MULTI_SELECT_MODE

const InternshipsShiftsEditor = props => {
  const [numberOfDays, setNumberOfDays] = useState(0)
  const [supervisorsEditSchedule, setSupervisorsEditSchedule] = useState(false)

  useEffect(() => {
    if (props.endDate && props.startDate) {
      setNumberOfDays(props.endDate.diff(props.startDate, 'days') + 1)
    }
  }, [props.startDate, props.endDate])

  useEffect(() => {
    if ([ROLE_SUPERVISOR].includes(props.getUser.roles[0]) && props.getUser.school !== null) {
      fetchSchoolOptions(props.getUser.school, props.getUser).then(response => {
        response.data.map(schoolOption => {
          if (schoolOption.optionType.type === SUPERVISORS_EDIT_SCHEDULE) {
            setSupervisorsEditSchedule(schoolOption.optionEnabled)
          }
        })
      })
    }
  }, [props.getUser])

  const handleOpenChatForInternship = () => {
    props.onOpenChatForInternship()
  }

  const handleShiftsWidgetCoverClick = (e) => {
    if (props.inWidgetMode) {
      props.onShiftsWidgetCoverClick(e)
    }
  }

  const toggleColumnSelect = columnIndex => {
    if (props.multiSelectionMode) {
      addColumnShiftsToMultiSelection(columnIndex)
    }
  }

  const addColumnShiftsToMultiSelection = columnIndex => {
    const columnDate = props.startDate.clone().add(columnIndex, 'days')
    const shiftsToAdd = []

    props.internships.forEach((internship, rowIndex) => {
      if (!canOnlyEditEventCode(props.getUser, internship)) {
        const shiftOfTheDay = getShiftByDayAndInternship(internship, columnDate)

        shiftsToAdd.push(getMultiSelectionShiftEncapsulation(
          createShiftOrConfigureIt(shiftOfTheDay, internship.id, columnDate.clone(), columnDate.clone()),
          rowIndex,
          columnIndex,
          internship
        ))
      }
    })

    if (shiftsToAdd.length > 0) {
      props.multiSelectionAddingHandler(shiftsToAdd, COLUMN)
    }
  }

  const addToMultiSelection = (shiftIndexEncapsulation, internship) => {
    props.addToMultiSelection(shiftIndexEncapsulation, internship)
  }

  const removeFromMultiSelection = (shiftIndexEncapsulation, internship) => {
    props.removeFromMultiSelection(shiftIndexEncapsulation, internship)
  }

  const renderTableHeader = () => {
    if (props.startDate && props.endDate) {
      const currentDate = moment(props.startDate).locale(LANGUAGE_TAG_EN)
      const dates = []

      for (let i = 0; i < numberOfDays; i++) {
        const dateDayToString = props.t(currentDate.format('dddd'))
        const name = dateDayToString[0].toUpperCase() + dateDayToString[1] + '.'
        const dateAsString = currentDate.format(DATE_FORMAT_TYPE)

        dates.push({ name, date: dateAsString })

        currentDate.add(1, 'days')
      }

      return (
        <thead>
          <tr>
            <th>
              <div className='table-first-header'>
                <b>
                  {props.t('Internships')} {props.checkedNumber > 0 && `(${props.checkedNumber})`}
                </b>
                {!props.isReadOnly && (
                  <div>
                    <Checkbox checked={props.allChecked} onChange={props.onCheckAll} />
                  </div>
                )}
              </div>
            </th>
            {renderTableHeaderCells(dates)}
          </tr>
        </thead>
      )
    }
  }

  const renderTableHeaderCells = dates => {
    const CellType = getCellByAppState()

    return dates.map((d, index) => {
      const pauseQuotasData = props.getPauseQuotasCounts[index]
      const shiftsData = props.getShiftsCounts[index]
      const key = index + '_' + d.name
      const sharedCellProps = {
        name: d.name,
        date: d.date,
        onClick: () => toggleColumnSelect(index)
      }

      if (pauseQuotasData) {
        return (
          <CellType
            key={key}
            {...sharedCellProps}
            total={pauseQuotasData.total}
            shiftsTotal={shiftsData.total}
            details={pauseQuotasData.details}
            shiftsDetails={shiftsData.details}
          />
        )
      }

      return (
        <CellType
          key={key}
          {...sharedCellProps}
        />
      )
    })
  }

  const getCellByAppState = () => {
    if (isObject(props.getSelectedSector) && props.getSelectedSector.id > -1) {
      return props.getSelectedSector.pausesEnabled
        ? PausesHeaderCell
        : TotalHeaderCell
    }

    return SimpleHeaderCell
  }

  const renderTableBody = () => {
    const rows = []

    if (props.internships && numberOfDays) {
      const startDate = props.startDate

      props.internships.forEach((i, index) => {
        rows.push(
          <Line
            key={'internship-' + i.id}
            checked={props.checkedInternships[i.id]}
            inDraftMode={props.inDraftMode}
            internship={i}
            internshipIndex={index}
            startDate={startDate}
            numberOfDays={numberOfDays}
            selectedShifts={props.selectedShifts}
            limitedActions={props.limitedActions}
            isReadOnly={props.isReadOnly}
            userIsNurse={props.userIsNurse}
            setIntershipToValidate={props.setIntershipToValidate}
            onClickedDraft={props.onClickedDraft}
            onDeleteSchedule={props.onDeleteSchedule}
            onStudentSelected={props.onStudentSelected}
            onInternshipChecked={props.onInternshipChecked}
            onInternshipSelected={props.onInternshipSelected}
            multiSelectionMode={props.multiSelectionMode}
            inWidgetMode={props.inWidgetMode}
            onOpenChatForInternship={handleOpenChatForInternship}
            addToMultiSelection={addToMultiSelection}
            removeFromMultiSelection={removeFromMultiSelection}
            multiSelectionAddingHandler={props.multiSelectionAddingHandler}
            onDesiderataSelect={props.onDesiderataSelect}
            supervisorsEditSchedule={supervisorsEditSchedule}
          />
        )
      })
    }

    return (
      <tbody>
        {rows}
      </tbody>
    )
  }

  const renderContainer = () => {
    if (props.loading) {
      return getLoadingAnimation()
    }

    return (
      <table
        className={props.multiSelectionMode ? 'table-in-multi-select-mode' : ''}
        onClick={handleShiftsWidgetCoverClick}
      >
        {renderTableHeader()}
        {renderTableBody()}
      </table>
    )
  }

  const getLoadingAnimation = () => {
    return (
      <div className='fill-content'>
        <div className='table-loading-ring' />
      </div>
    )
  }

  return renderContainer()
}

export default connect(mapStateToProps, mapDispatchToProps)(InternshipsShiftsEditor)
