import React, { createContext, useContext, useCallback, useState, useEffect } from 'react'

import { getUser } from '../reducers/UserReducer'
import { connect } from 'react-redux'
import { getInternshipsMessages, getInternshipsMessagesCount, readInternshipMessages, unreadInternshipMessage } from '../utils/api/internshipMessage'
import useQuery from '../hooks/useQuery'
import { Metadata } from '../utils/http'
import ChatManager from '../Components/shared/ChatManager'
import { displayError } from '../utils/apiHelper'
import { GlobalContext } from './GlobalProvider'
import { isArray, isObject } from 'lodash'

const mapStateToProps = state => ({
  user: getUser(state.getUser)
})

export const InternshipMessageContext = createContext()

const InternshipMessageProvider = ({ children, user }) => {
  const { unreadMessages, setUnreadMessages } = useContext(GlobalContext)

  const [display, setDisplay] = useState(false)
  const [selectedInternship, setSelectedInternship] = useState(null)
  const [trackedInternships, setTrackedInternships] = useState([])
  const [messagesCount, setMessagesCount] = useState({})

  const fetchInternshipsMessages = useCallback((currentUser, pagination) => {
    return currentUser.token ? getInternshipsMessages(currentUser, user, pagination) : false
  }, [user.id])

  const internshipsMessages = useQuery({ request: fetchInternshipsMessages, defaultMetadata: new Metadata({ pageSize: 50 }) })

  const fetchMessageCount = useCallback(ids => {
    getInternshipsMessagesCount(user, { ids }).then(json => {
      setMessagesCount(Object.keys(json.data).reduce((acc, key) => {
        acc[key] = json.data[key]

        return acc
      }, { ...messagesCount }))
    })
  }, [user.id, messagesCount, setMessagesCount])

  useEffect(() => {
    let interval

    if (trackedInternships.length > 0) {
      interval = setInterval(() => fetchMessageCount(trackedInternships), 30000)
    }

    return () => clearInterval(interval)
  }, [fetchMessageCount, trackedInternships])

  const onInternshipSelect = useCallback((internship, messageToUnread) => {
    setSelectedInternship(internship)

    const target = internship === null ? selectedInternship : internship

    if (target !== null && messageToUnread && isObject(messageToUnread)) {
      unreadInternshipMessage(user, messageToUnread.id).then(() => {
        internshipsMessages.mutate(data => data.map(i => {
          if (i.id === target.id) {
            setUnreadMessages(unreadMessages + 1)

            i.unreadMessages += 1
          }

          return i
        }))

        if (messagesCount[target.id]) {
          setMessagesCount({ ...messagesCount, [target.id]: { ...messagesCount[target.id], unreadMessages: 1 } })
        }
      })
    } else if (target !== null) {
      readInternshipMessages(user, target).then(() => {
        internshipsMessages.mutate(data => data.map(i => {
          if (i.id === target.id) {
            setUnreadMessages(unreadMessages - i.unreadMessages)

            i.unreadMessages = 0
          }

          return i
        }))
        if (messagesCount[target.id]) {
          setMessagesCount({ ...messagesCount, [target.id]: { ...messagesCount[target.id], unreadMessages: 0 } })
        }
      }).catch(error => {
        displayError(error, 'internship_message.read')
      })
    }
  }, [selectedInternship, setSelectedInternship, user.id, internshipsMessages.mutate, unreadMessages, setUnreadMessages, messagesCount, setMessagesCount])

  const onInternshipTrack = useCallback(internshipIds => {
    if (isArray(internshipIds)) {
      setTrackedInternships(internshipIds)

      if (internshipIds.length > 0) {
        fetchMessageCount(internshipIds)
      }
    }
  }, [trackedInternships, setTrackedInternships])

  return (
    <InternshipMessageContext.Provider
      value={{ display, internshipsMessages, messagesCount, setDisplay, setSelectedInternship: onInternshipSelect, setTrackedInternships: onInternshipTrack }}
    >
      {children}
      <ChatManager internship={selectedInternship} onClose={(messageToUnread) => onInternshipSelect(null, messageToUnread)} />
    </InternshipMessageContext.Provider>
  )
}

export default connect(mapStateToProps)(InternshipMessageProvider)
