import React, { useCallback, useContext, useMemo, useState } from 'react'

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import Cookie from 'js-cookie'
import { Button, notification, Tag, Tooltip } from 'antd'
import InternshipsFilters from './InternshipsFilters'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons'
import ChangedInternshipSectorModal from './ChangedInternshipSectorModal'
import { DATE_FORMAT_API, internshipStates, internshipStatesString, ORDER_BY, ROLE_NURSE, ROLE_OBSERVER } from '../../../../utils/constants'
import { getUser } from '../../../../reducers/UserReducer'
import { InternshipContext } from '../../../../Context/InternshipsManager/InternshipProvider'
import { InternshipsManagerContext } from '../../../../Context/InternshipsManager/InternshipsManagerProvider'
import {
  getCoordinatorFullname, getEndDate, getSchoolAbbreviation, getSchoolName, getSchoolYearName, getSectionNameOrFull, getSectorName, getStartDate, getState,
  getStudentFullname
} from '../../../../utils/entities/internship'
import { TableParameters } from '../../../../utils/entities/tableParameters'
import { getDocument } from '../../../../utils/api/export'
import DataTable from '../../../../Components/shared/DataTable/DataTable'
import { ComponentAction } from '../../../../Components/shared/DataTable/utils/actions'
import { buildFilters, Filter } from '../../../../Components/shared/DataTable/utils/tableFilter'
import InternshipAcceptationButton from './InternshipAcceptationButton'
import StudentQuickMenu from '../../../../Components/shared/InternshipsManager/StudentQuickMenu'
import InternshipsManagerActionsHeaderExportModal from '../../../../Components/shared/InternshipsManagerActionsHeaderExportModal'
import { orderByNameSectors } from '../../../../Selectors/Sectors/SectorsSelector'
import InternshipRejection from '../../../../Components/shared/InternshipsManager/InternshipRejection'
import { acceptInternship, exportTimeline, resetToPending } from '../../../../utils/api/internship'
import { getLanguageByLocale } from '../../../../utils'

const {
  ACCEPTED,
  ACCEPTED_UNMANAGED,
  CANCELED,
  PENDING,
  REFUSED,
  PRE_ACCEPTED,
  PENDING_NO_SECTOR,
  PENDING_STUDENT_DATA_MISSING,
  AUTO_ACCEPTED,
  CREATED,
  SCHEDULE_ADDED,
  SCHEDULE_TO_VALIDATE,
  SCHEDULE_VALIDATED
} = internshipStates
const MININAL_STYLE = { minWidth: '26px', minHeight: '26px' }
const REFUSED_ACTION = 'REFUSED'
const UPDATE_SECTOR_ACTION = 'UPDATE_SECTOR'

const mapStateToProps = state => ({
  sectors: orderByNameSectors(state),
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InternshipsTable = ({ data, loading, t, user, parameters, sectors, onParametersChange, onDataChange }) => {
  const { onUpdate, refreshQuotas, setLocalInternship } = useContext(InternshipContext)
  const { setStudent, setInternship } = useContext(InternshipsManagerContext)

  const [visibleModal, setVisibleModal] = useState(false)
  const [selectedInternship, setSelectedInternship] = useState({ data: null, action: null })

  const maxHeight = useMemo(() => `calc(100vh - ${Cookie.get('switch_user_id') ? '240px' : '199px'})`, [Cookie.get('switch_user_id')])

  const renderInternshipState = useCallback(internship => {
    const state = getState(internship)
    let color = 'red'

    if (state === PRE_ACCEPTED) {
      color = 'cyan'
    } else if (state === PENDING_STUDENT_DATA_MISSING) {
      color = 'volcano'
    } else if (state === PENDING_NO_SECTOR) {
      color = ''
    } else if (state === CREATED) {
      color = ''
    } else if (state === PENDING) {
      color = 'blue'
    } else if (state === ACCEPTED) {
      color = 'green'
    } else if (state === AUTO_ACCEPTED) {
      color = 'lime'
    } else if (state === REFUSED) {
      color = 'red'
    } else if (state === CANCELED) {
      color = 'orange'
    } else if (state === ACCEPTED_UNMANAGED) {
      color = 'lime'
    } else if (state === SCHEDULE_ADDED) {
      color = 'green'
    } else if (state === SCHEDULE_VALIDATED) {
      color = 'green'
    } else if (state === SCHEDULE_TO_VALIDATE) {
      color = 'green'
    }

    return <Tag color={color}>{t(internshipStatesString[state])}</Tag>
  }, [t])
  const disableActions = useCallback((data, action) => {
    const baseAction = [action]
    let disabled = true

    if (!user.roles.includes(ROLE_OBSERVER)) {
      if (action === ACCEPTED) {
        disabled = [...baseAction, CANCELED, PENDING_STUDENT_DATA_MISSING, PENDING_NO_SECTOR, CREATED, SCHEDULE_VALIDATED, SCHEDULE_TO_VALIDATE, SCHEDULE_ADDED].includes(data.state)
      } else if (action === REFUSED) {
        disabled = [...baseAction, CANCELED, PENDING_STUDENT_DATA_MISSING].includes(data.state) || user.roles.includes(ROLE_NURSE)
      } else if (action === PENDING) {
        disabled = [...baseAction, CANCELED, PENDING_STUDENT_DATA_MISSING].includes(data.state)
      }
    }

    return disabled
  }, [user.roles])

  const columns = useMemo(() => [
    { title: t('Sector'), orderBy: ORDER_BY.SECTOR, key: getSectorName },
    { title: t('School'), orderBy: ORDER_BY.SCHOOL, key: getSchoolAbbreviation, tooltip: getSchoolName },
    { title: t('Section'), orderBy: ORDER_BY.SECTION, key: getSectionNameOrFull },
    { title: t('School year'), orderBy: ORDER_BY.SCHOOL_YEAR_NAME, key: getSchoolYearName },
    { title: t('Student name'), orderBy: ORDER_BY.LASTNAME, key: getStudentFullname },
    { title: t('State'), orderBy: ORDER_BY.STATE, key: i => renderInternshipState(i) },
    { title: t('Coordinator'), orderBy: ORDER_BY.COORDINATOR, key: getCoordinatorFullname },
    { title: t('Start'), orderBy: ORDER_BY.START_DATE, key: getStartDate },
    { title: t('End'), orderBy: ORDER_BY.END_DATE, key: getEndDate }
  ], [renderInternshipState, t])

  const actions = useMemo(() => [
    new ComponentAction({
      Component: InternshipAcceptationButton,
      props: (data) => ({
        disabled: disableActions(data, ACCEPTED),
        internship: data,
        style: MININAL_STYLE,
        size: 'small',
        onClick: () => {
          acceptInternship({ id: data.id }, user).then(() => {
            onDataChange(parameters)
            notification.success({ message: t('Internship accepted'), placement: 'bottomRight' })
          })
        }
      }),
      containerClassName: 'antd-button-without-padding'
    }),
    new ComponentAction({
      Component: Button,
      props: (data) => ({
        style: { ...MININAL_STYLE, marginLeft: '5px' },
        className: 'antd-button-without-padding',
        children: (
          <Tooltip title={t('Refuse')}>
            <FontAwesomeIcon
              height='14px'
              color={disableActions(data, REFUSED) ? 'grey' : '#F44336'}
              icon={faTimes}
            />
          </Tooltip>
        ),
        size: 'small',
        disabled: disableActions(data, REFUSED),
        onClick: () => setSelectedInternship({ data, action: REFUSED_ACTION })
      }),
      containerClassName: 'antd-button-without-padding'
    }),
    new ComponentAction({
      Component: Button,
      props: (data) => ({
        style: { ...MININAL_STYLE, marginLeft: '5px', marginRight: '5px' },
        className: 'antd-button-without-padding',
        children: (
          <Tooltip title={t('Pending')}>
            <FontAwesomeIcon
              height='14px'
              color={disableActions(data, PENDING) || user.roles.includes(ROLE_NURSE) ? 'grey' : '#03A9F4'}
              icon={faQuestion}
            />
          </Tooltip>
        ),
        size: 'small',
        disabled: disableActions(data, PENDING) || user.roles.includes(ROLE_NURSE),
        onClick: () => {
          resetToPending({ id: data.id }, user).then(() => {
            onDataChange(parameters)
            notification.success({ message: t('Internship reset to pending'), placement: 'bottomRight' })
          })
        }
      }),
      containerClassName: 'antd-button-without-padding'
    }),
    new ComponentAction({
      Component: StudentQuickMenu,
      props: {
        noMargin: true,
        onSelectedInternshipInfo: setInternship,
        onSelectedStudentInfo: setStudent,
        onSelectedForSectorChange: (internship) => {
          setSelectedInternship({ data: internship, action: UPDATE_SECTOR_ACTION })
          setLocalInternship(internship)
        }
      },
      containerClassName: 'antd-button-without-padding',
      dataKey: 'internship'
    })
  ], [
    t, disableActions, onDataChange, setInternship, setLocalInternship, setStudent, setSelectedInternship, onUpdate, user
  ])

  const filters = useMemo(() => buildFilters([
    new Filter({
      key: 'filters',
      label: t('Filters'),
      component: () => {
        return (
          <InternshipsFilters
            onDateChange={handlePeriodChange}
            dates={{
              startDate: parameters.period.startDate,
              endDate: parameters.period.endDate
            }}
            onStatesChanges={handleStateChange}
            states={parameters.filters.states}
          />
        )
      }
    })
  ]), [parameters.filters.states, parameters.period, t])

  const handlePeriodChange = useCallback(dates => {
    onParametersChange(new TableParameters({
      ...parameters,
      period: {
        startDate: moment(dates.startDate).format(DATE_FORMAT_API),
        endDate: moment(dates.endDate).format(DATE_FORMAT_API)
      }
    }))
  }, [onParametersChange, parameters])

  const handleStateChange = useCallback(states => {
    onParametersChange(new TableParameters({
      ...parameters,
      filters: {
        ...parameters.filters,
        states: states
      }
    }))
  }, [onParametersChange, parameters])

  const handleExport = useCallback((startDate, endDate, checked, filter) => {
    let parameters = {
      start: startDate.format(DATE_FORMAT_API),
      end: endDate.format(DATE_FORMAT_API),
      language: getLanguageByLocale(user.language)
    }

    if (checked) {
      parameters = { ...parameters, filter: filter }
    }

    exportTimeline(user, parameters).then(json => {
      if (json) {
        getDocument(
          json.job,
          user,
          () => notification.error({ message: t('Spreadsheet download failed'), placement: 'bottomRight' })
        )
      } else {
        notification.error({ message: t('Internships export failed'), placement: 'bottomRight' })
      }
    })
  }, [])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        data={data.data}
        metadata={data.meta.pages}
        loading={loading}
        columns={columns}
        actions={actions}
        parameters={parameters}
        onParametersChange={onParametersChange}
        filters={filters}
        onBasicExport={() => setVisibleModal(true)}
        customLineStyle={{
          height: '7vh',
          alignItems: 'center'
        }}
      />
      <InternshipsManagerActionsHeaderExportModal
        startDate={moment(parameters.period.startDate ?? 'now')}
        weeksShownCount={4}
        filterSearch={parameters.search}
        filterStates={parameters.filters.states ?? []}
        orderBy={parameters.orderBy}
        orderDirection={parameters.orderDirection}
        onCancel={() => setVisibleModal(false)}
        onExport={handleExport}
        visible={visibleModal}
      />
      {selectedInternship.action === UPDATE_SECTOR_ACTION && (
        <ChangedInternshipSectorModal
          internship={selectedInternship.data}
          sectors={sectors}
          visible={!!selectedInternship.data}
          onCancel={() => setSelectedInternship({ data: null, action: null })}
          onUpdate={() => {
            setSelectedInternship({ data: null, action: null })
            refreshQuotas()
          }}
        />
      )}
      {selectedInternship.action === REFUSED_ACTION && (
        <InternshipRejection
          internship={selectedInternship.data}
          onClose={ok => {
            if (ok) {
              refreshQuotas()
            }

            setSelectedInternship({ data: null, action: null })
          }}
        />
      )}
    </div>

  )
}

export default connect(mapStateToProps)(InternshipsTable)
