import React, { createContext, useContext, useMemo, useState } from 'react'

import { connect } from '../../reducers/Dispatchers'
import { getUser } from '../../reducers/UserReducer'
import { getDataReady } from '../../reducers/DataReadyReducer'
import { getTaggedEntities } from '../../reducers/TagsReducer'
import { getTranslate } from 'react-localize-redux'
import { getColumnsDates } from '../../Components/shared/InternshipsManager/utils/utils'
import StudentInfo from '../../Components/shared/StudentInfo'
import InternshipInfo from '../../Components/shared/InternshipInfo'
import { GlobalFiltersContext } from '../../Providers/GlobalFiltersProvider'

const InternshipsManagerContext = createContext()

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser),
  getDataReady: getDataReady(state.getDataReady),
  getTaggedEntities: getTaggedEntities(state.tags)
})

let InternshipsManagerProvider = ({
  addMode, children, quotas, t, user, getDataReady, onEarlyStartDate, onStudentSelected, onInternshipSelected, onInternshipDelete,
  refreshInternships, refreshQuotas, setInternshipToValidate, internships, weeksShownCount
}) => {
  const { internshipDate } = useContext(GlobalFiltersContext)

  const [highlightedIndexes, setHighlightedIndexes] = useState(null)
  const [student, setStudent] = useState(null)
  const [internship, setInternship] = useState(null)

  const dateColumns = useMemo(() => getColumnsDates(internshipDate, weeksShownCount), [weeksShownCount, internshipDate])

  return (
    <>
      <InternshipsManagerContext.Provider value={{
        addMode,
        dateColumns,
        internships,
        quotas,
        getDataReady,
        highlightedIndexes,
        user,
        onEarlyStartDate,
        onInternshipSelected,
        onInternshipDelete,
        onLineHover: setHighlightedIndexes,
        onTableLeave: () => setHighlightedIndexes(null),
        onStudentSelected,
        refreshInternships,
        refreshQuotas,
        setInternshipToValidate,
        t,
        setStudent,
        setInternship
      }}
      >
        {children}
      </InternshipsManagerContext.Provider>
      <StudentInfo student={student} onClose={() => setStudent(null)} />
      <InternshipInfo internship={internship} onClose={() => setInternship(null)} />
    </>
  )
}

InternshipsManagerProvider = connect(mapStateToProps)(InternshipsManagerProvider)

export {
  InternshipsManagerProvider as default,
  InternshipsManagerContext
}
