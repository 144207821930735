import React, { useCallback, useRef } from 'react'

const InfiniteScroll = ({ children, onBottomReach, hasMore }) => {
  const scrollTarget = useRef(null)

  const onScroll = useCallback(e => {
    const { scrollTop, scrollHeight, offsetHeight } = e.target

    if (hasMore && scrollTop + offsetHeight === scrollHeight) {
      onBottomReach()
    }
  }, [onBottomReach, hasMore])

  return React.cloneElement(children, { ref: scrollTarget, onScroll })
}

export default InfiniteScroll
