import React, { useEffect } from 'react'

import moment from 'moment'
import ShiftsManager from '../../Components/shared/ShiftsManager/ShiftsManager'
import Analytics from '../../utils/analytics'
import { DATE_FORMAT_API } from '../../utils/constants'
import ShiftManagerProvider from '../../Providers/ShiftManagerProvider'

import '../../assets/internships-manager.scss'

const NUMBER_OF_WEEK_TO_PRELOAD = 0

const ShiftsManagerView = ({ currentDate, displayManagePresets, isReadOnly }) => {
  const start = currentDate || moment().startOf('isoWeek').hours(0).minutes(0).seconds(0)
  const end = start.clone().add(3, 'week').endOf('isoWeek').add(NUMBER_OF_WEEK_TO_PRELOAD, 'week')

  useEffect(() => {
    Analytics.pageView('/institution-shift-manager')
  }, [])

  return (
    <ShiftManagerProvider>
      <ShiftsManager
        start={start.format(DATE_FORMAT_API)}
        end={end.format(DATE_FORMAT_API)}
        limitedActions={isReadOnly}
        isReadOnly={isReadOnly}
        displayManagePresets={displayManagePresets}
      />
    </ShiftManagerProvider>
  )
}

export default ShiftsManagerView
