import React, { createContext, useMemo, useState } from 'react'

import { Drawer } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import InstitutionDocumentModal from '../Forms/InstitutionDocumentModal'
import InstitutionDocumentsManager from '../../Components/shared/FilesManager/InstitutionDocumentsManager/InstitutionDocumentsManager'
import { isSupervisor } from '../../utils/roles'

export const InstitutionDocumentsDrawerContext = createContext()

export const DEFAULT_STATE = { data: null }

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InstitutionDocumentsDrawer = ({ children, user, t }) => {
  const [selectedInstitution, setSelectedInstitution] = useState(DEFAULT_STATE)

  const isReadOnly = useMemo(() => isSupervisor(user) || (selectedInstitution.data && selectedInstitution.data.managed), [user.roles, selectedInstitution])

  return (
    <>
      <InstitutionDocumentsDrawerContext.Provider value={{ setSelectedInstitution }}>
        {children}
      </InstitutionDocumentsDrawerContext.Provider>
      <Drawer
        title={t('institution_documents_drawer.title')}
        width='650px'
        onClose={() => setSelectedInstitution(DEFAULT_STATE)}
        visible={!!selectedInstitution.data}
        zIndex={5000}
        destroyOnClose
      >
        <InstitutionDocumentModal>
          <InstitutionDocumentsManager institution={selectedInstitution.data} readOnly={isReadOnly} />
        </InstitutionDocumentModal>
      </Drawer>
    </>
  )
}

export default connect(mapStateToProps)(InstitutionDocumentsDrawer)
