import {
  addTranslationForLanguage,
  getTranslate,
  getActiveLanguage,
  setActiveLanguage,
  getLanguages
} from 'react-localize-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getAppBanner, setAppBanner } from '../AppBannerReducer'
import { getDataReady, setDataReady } from '../DataReadyReducer'
import {
  getInstitutions, setInstitutions, setInstitutionTimePresets, removeInstitutionUser, clearInstitutionsPresets, fetchInstitutions
} from '../InstitutionsReducer'
import { getSchools, setSchools, updateSchool, addNewSchool, removeSchool, removeSchoolUser, addSchoolUser } from '../SchoolsReducer'
import { getStudents, setStudents, removeStudent, fetchStudents, saveStudent } from '../StudentsReducer/actions'
import { getTheme, setTheme } from '../ThemeReducer'
import {
  getUser, setUser, setUserManagedSectors, updateUserProfile, fetchCanSchoolEditSchedulesByInstitution, setCanSchoolEditSchedules, getCanSchoolEditSchedules,
  updateNissByStudent
} from '../UserReducer'
import { getAppScroll, setAppWidth, setAppHorizontalScroll, clearAppScrollWidthValues } from '../AppScrollReducer'
import { setChangelog, saveChangelog, removeChangelog, dismissUserNotification, resetChangelog } from '../ChangelogReducer'
import { getEvaluationQuestions, fetchEvaluationQuestions, clearEvaluationQuestions } from '../EvaluationQuestionsReducer'
import {
  getActTypeCategoriesForSchool, fetchActTypeCategories, createActTypeCategory, editActTypeCategory, deleteActTypeCategory
} from '../ActTypeCategoriesReducer'
import { getSatisfactionRatioFilters, setSatisfactionRatioFilter } from '../SatisfactionRatioFilterReducer'
import {
  getTags, fetchTags, getTagsLoading, getTagsFetched, saveTag, deleteTag, toggleActiveTag, getTagsByEntityType, tagEntity, fetchTaggedEntities, getTaggedEntities,
  resetTags, toggleTag, getEnabledTagsForEntityName, addTaggedEntity
} from '../TagsReducer'
import {
  getBadges, setBadges, getBadgesAssignations, setBadgesAssignations, setStudentBadges, getPendingAssignation, setPendingAssignationData, getDirtyBadgeAssignationId,
  setDirtyBadgeAssignationId, getBadgeAssignationErrorMessage, setBadgeAssignationErrorMessage, fetchBadgesAssignationsByDate, fetchBadges, fetchStudentBadges,
  fetchBadgeRFIDNumber, addBadgeAssignation, updateBadgeAssignation, modifyBadgeAssignation, deleteBadgeAssignation, removeBadgeAssignation
} from '../BadgesReducer'
import { isLocalAdmin } from '../../Selectors/localAdministrator/localAdministrator'
import { selectConcatStudentsBadges } from '../../Selectors/Badges/badges'
import { getUserManagedSectorIdList } from '../../Selectors/user/user'
import { selectInstitutionSectorsAsObject, selectQuotasAsTree } from '../../Selectors/quotas'
import { selectSectorsAsObject } from '../../Selectors/Sectors/SectorsSelector'
import { selectInstitutionConstraintsByInstitution } from '../../Selectors/InstitutionConstraints/InstitutionConstraintsSelector'
import {
  fetchInstitutionFieldsTypes, getStudentFreeFields, setStudentFreeFields, getInstititionFieldsTypes, getLoadMessageFieldsTypes, loadMessageFieldsTypes,
  setInstititionFieldsTypes, setFreeFieldType, setStudentsFreeFields, fetchStudentFreeField, deleteFreeFieldType, addOrUpdateFreeFieldType, updateFreeFieldType
} from '../FreeFieldsReducer'
import {
  getStudentInformations, setStudentInformations, getStudentExtraInformations, setStudentExtraInformations, setStudentPrivacyInformations, getStudentProfileImage,
  setStudentProfileImage, fetchStudentExtraInformations, fetchStudentProfileImage, removeStudentProfileImage
} from '../ProfilPageReducer'
import {
  getQuotas, getQuotasState, addQuota, removeQuota, updateQuota, duplicateQuota,
  fetchSectorQuotas, fetchSectorsQuotas, fetchInstitutionQuotas, fetchQuotasTree
} from '../QuotasReducer/actions'
import {
  getStudentsExtraInformations, setStudentsExtraInformations, getStudentsPrivacyInformations, setStudentsOptIn, fetchStudentExtraInformationsById, modifyStudentOptIn
} from '../StudentsExtraInfos'
import {
  setInstitutionTemplates, setInstitutionTemplateVariables, setInstitutionTemplatesState, fetchInstitutionTemplates
} from '../InstitutionTemplatesReducer/InstitutionTemplatesReducer'
import {
  getShifts, setInternshipShifts, setMultipleInternshipsShifts, fetchInternshipShifts, fetchMultipleInternshipShifts, addShiftFromPreset, updateShiftFromPreset,
  acceptDesiderataByUser, removeShift, removeInternshipsShifts
} from '../ShiftsReducer/ShiftsReducer'
import {
  getSchoolSections, addSchoolSection, removeSchoolSection, fetchSchoolSectionsBySchool, modifySchoolSection
} from '../SchoolSectionsReducer/actions'
import {
  getInternships, setInternshipDocuments, fetchInternshipDocuments, updateInternshipWithCallback
} from '../InternshipsReducer/InternshipsReducer'
import {
  getShiftPresets, getShiftPresetsLoading, fetchInstitutionShiftsPresets
} from '../ShiftsPresetsReducer/actions'
import {
  getSectors, fetchSectorsByInstitution, fetchSectorsFilesCount, setSectors
} from '../SectorsReducer/actions'
import {
  getPauseQuotas, fetchPausesQuotasByInstitution, createPauseQuota, updatePauseQuota, deletePauseQuota
} from '../PauseQuotasReducer/PauseQuotasReducer'
import {
  getAppStates, setPauseActivationState
} from '../AppStates/actions'
import {
  getInstitutionOptions, getInstitutionOptionsLoaded, fetchInstitutionOptions
} from '../InstitutionOptionsReducer/actions'
import {
  getShiftsManagerState, getSelectedInstitution, getSelectedSector, getPauseQuotasCounts, getShiftsCounts, getPauseInstitutions, getPauseQuotasState, getShiftsState,
  fetchPauseQuotasCountsPerSector, fetchShiftsCountsPerSector, fetchInstitutionsWithActivatedPauseQuotaOption, setSelectedInstitution, setSelectedSector
} from '../ShiftsManagerReducer/actions'
import {
  getInstitutionConstraints, addInstitutionConstraint, fetchInstitutionConstraints, removeInstitutionConstraint, updateInstitutionConstraint
} from '../InstitutionConstraintsReducer/actions'
import { fetchAllInstitutionOptionTypes, getInstitutionOptionTypes } from '../InstitutionOptionTypesReducer/actions'
import { fetchAllContactPersonsSection } from '../ContactPersonsReducer/actions'
import { LANGUAGE_LOCALE_TAG_FR_BE } from '../../utils/constants'

export function mapStateToProps (state, ownProps) {
  return {
    t: getTranslate(state.locale),
    getActiveLanguage: getActiveLanguage(state.locale)?.code ?? LANGUAGE_LOCALE_TAG_FR_BE,
    getActTypeCategoriesForSchool: getActTypeCategoriesForSchool(state.actTypeCategories, ownProps),
    getAppBanner: getAppBanner(state.getAppBanner),
    getAppScroll: getAppScroll(state.getAppScroll),
    getBadges: getBadges(state.getBadgesProperties),
    getBadgesAssignations: getBadgesAssignations(state),
    getBadgeAssignationErrorMessage: getBadgeAssignationErrorMessage(state.getBadgesProperties),
    getDataReady: getDataReady(state.getDataReady),
    getDirtyBadgeAssignationId: getDirtyBadgeAssignationId(state.getBadgesProperties),
    getEvaluationQuestions: getEvaluationQuestions(state.getEvaluationQuestions),
    getInstitutions: getInstitutions(state),
    getInstititionFieldsTypes: getInstititionFieldsTypes(state.getFreeFields),
    getLanguages: getLanguages(state.locale),
    getPendingAssignation: getPendingAssignation(state),
    getSatisfactionRatioFilters: getSatisfactionRatioFilters(state),
    getSchools: getSchools(state.getSchools),
    getStudents: getStudents(state.getStudents),
    getStudentFreeFields: getStudentFreeFields(state.getFreeFields),
    getTags: getTags(state.tags),
    getTaggedEntities: getTaggedEntities(state.tags),
    getTagsLoading: getTagsLoading(state.tags),
    getTagsFetched: getTagsFetched(state.tags),
    getTheme: getTheme(state.getTheme),
    getUser: getUser(state.getUser),
    getUserManagedSectorIdList: getUserManagedSectorIdList(state.getUser),
    isLocalAdmin: isLocalAdmin(state.getUser),
    selectConcatStudentsBadges: selectConcatStudentsBadges(state),
    getStudentInformations: getStudentInformations(state.profileDatas),
    getStudentExtraInformations: getStudentExtraInformations(state.profileDatas),
    getStudentProfileImage: getStudentProfileImage(state.profileDatas),
    selectInstitutionSectorsAsObject: selectInstitutionSectorsAsObject(state),
    getQuotas: getQuotas(state),
    getQuotasState: getQuotasState(state),
    getStudentsExtraInformations: getStudentsExtraInformations(state.studentsExtraInformations),
    getStudentsPrivacyInformations: getStudentsPrivacyInformations(state.studentsExtraInformations),
    getShifts: getShifts(state.shifts),
    getCanSchoolEditSchedules: getCanSchoolEditSchedules(state.getUser),
    getInternships: getInternships(state.internships),
    getSchoolSections: getSchoolSections(state.schoolSections),
    getShiftPresets: getShiftPresets(state),
    getShiftPresetsLoading: getShiftPresetsLoading(state),
    getSectors: getSectors(state),
    selectSectorsAsObject: selectSectorsAsObject(state),
    selectQuotasAsTree: selectQuotasAsTree(state),
    getPauseQuotas: getPauseQuotas(state),
    getAppStates: getAppStates(state),
    getInstitutionOptionTypes: getInstitutionOptionTypes(state),
    getInstitutionOptions: getInstitutionOptions(state.institutionOptions),
    getInstitutionOptionsLoaded: getInstitutionOptionsLoaded(state.institutionOptions),
    getShiftsManagerState: getShiftsManagerState(state),
    getSelectedInstitution: getSelectedInstitution(state),
    getSelectedSector: getSelectedSector(state),
    getPauseQuotasCounts: getPauseQuotasCounts(state),
    getShiftsCounts: getShiftsCounts(state),
    getPauseInstitutions: getPauseInstitutions(state),
    getPauseQuotasState: getPauseQuotasState(state),
    getShiftsState: getShiftsState(state),
    getInstitutionConstraints: getInstitutionConstraints(state),
    selectInstitutionConstraintsByInstitution: selectInstitutionConstraintsByInstitution(state),
    getLoadMessageFieldsTypes: getLoadMessageFieldsTypes(state)
  }
}

export const mapDispatchToProps = dispatch => ({
  addBadgeAssignation: bindActionCreators(addBadgeAssignation, dispatch),
  addNewSchool: bindActionCreators(addNewSchool, dispatch),
  addSchoolUser: bindActionCreators(addSchoolUser, dispatch),
  addTaggedEntity: bindActionCreators(addTaggedEntity, dispatch),
  addTranslationForLanguage: bindActionCreators(
    addTranslationForLanguage,
    dispatch
  ),
  clearAppScrollWidthValues: bindActionCreators(
    clearAppScrollWidthValues,
    dispatch
  ),
  clearEvaluationQuestions: bindActionCreators(clearEvaluationQuestions, dispatch),
  clearInstitutionsPresets: bindActionCreators(clearInstitutionsPresets, dispatch),
  createActTypeCategory: bindActionCreators(createActTypeCategory, dispatch),
  deleteActTypeCategory: bindActionCreators(deleteActTypeCategory, dispatch),
  deleteBadgeAssignation: bindActionCreators(deleteBadgeAssignation, dispatch),
  deleteTag: bindActionCreators(deleteTag, dispatch),
  dismissUserNotification: bindActionCreators(dismissUserNotification, dispatch),
  editActTypeCategory: bindActionCreators(editActTypeCategory, dispatch),
  fetchActTypeCategories: bindActionCreators(fetchActTypeCategories, dispatch),
  fetchBadges: bindActionCreators(fetchBadges, dispatch),
  fetchBadgeRFIDNumber: bindActionCreators(fetchBadgeRFIDNumber, dispatch),
  fetchBadgesAssignationsByDate: bindActionCreators(fetchBadgesAssignationsByDate, dispatch),
  fetchEvaluationQuestions: bindActionCreators(fetchEvaluationQuestions, dispatch),
  fetchInstitutionFieldsTypes: bindActionCreators(fetchInstitutionFieldsTypes, dispatch),
  fetchInstitutions: bindActionCreators(fetchInstitutions, dispatch),
  fetchStudentBadges: bindActionCreators(fetchStudentBadges, dispatch),
  fetchStudentFreeField: bindActionCreators(fetchStudentFreeField, dispatch),
  fetchStudents: bindActionCreators(fetchStudents, dispatch),
  fetchTaggedEntities: bindActionCreators(fetchTaggedEntities, dispatch),
  fetchTags: bindActionCreators(fetchTags, dispatch),
  getEnabledTagsForEntityName: bindActionCreators(getEnabledTagsForEntityName, dispatch),
  getTagsByEntityType: bindActionCreators(getTagsByEntityType, dispatch),
  modifyBadgeAssignation: bindActionCreators(modifyBadgeAssignation, dispatch),
  removeBadgeAssignation: bindActionCreators(removeBadgeAssignation, dispatch),
  removeChangelog: bindActionCreators(removeChangelog, dispatch),
  removeInstitutionUser: bindActionCreators(removeInstitutionUser, dispatch),
  removeSchool: bindActionCreators(removeSchool, dispatch),
  removeSchoolUser: bindActionCreators(removeSchoolUser, dispatch),
  removeStudent: bindActionCreators(removeStudent, dispatch),
  resetChangelog: bindActionCreators(resetChangelog, dispatch),
  resetTags: bindActionCreators(resetTags, dispatch),
  saveStudent: bindActionCreators(saveStudent, dispatch),
  saveChangelog: bindActionCreators(saveChangelog, dispatch),
  saveTag: bindActionCreators(saveTag, dispatch),
  setActiveLanguage: bindActionCreators(setActiveLanguage, dispatch),
  setAppBanner: bindActionCreators(setAppBanner, dispatch),
  setAppHorizontalScroll: bindActionCreators(setAppHorizontalScroll, dispatch),
  setAppWidth: bindActionCreators(setAppWidth, dispatch),
  setBadges: bindActionCreators(setBadges, dispatch),
  setBadgesAssignations: bindActionCreators(setBadgesAssignations, dispatch),
  setChangeLog: bindActionCreators(setChangelog, dispatch),
  setDataReady: bindActionCreators(setDataReady, dispatch),
  setDirtyBadgeAssignationId: bindActionCreators(setDirtyBadgeAssignationId, dispatch),
  setInstititionFieldsTypes: bindActionCreators(setInstititionFieldsTypes, dispatch),
  setInstitutions: bindActionCreators(setInstitutions, dispatch),
  setInstitutionTimePresets: bindActionCreators(
    setInstitutionTimePresets,
    dispatch
  ),
  setPendingAssignationData: bindActionCreators(setPendingAssignationData, dispatch),
  setSatisfactionRatioFilter: bindActionCreators(setSatisfactionRatioFilter, dispatch),
  setSchools: bindActionCreators(setSchools, dispatch),
  setStudentFreeFields: bindActionCreators(setStudentFreeFields, dispatch),
  setStudents: bindActionCreators(setStudents, dispatch),
  setStudentBadges: bindActionCreators(setStudentBadges, dispatch),
  setTheme: bindActionCreators(setTheme, dispatch),
  setUser: bindActionCreators(setUser, dispatch),
  setUserManagedSectors: bindActionCreators(setUserManagedSectors, dispatch),
  tagEntity: bindActionCreators(tagEntity, dispatch),
  toggleActiveTag: bindActionCreators(toggleActiveTag, dispatch),
  toggleTag: bindActionCreators(toggleTag, dispatch),
  updateBadgeAssignation: bindActionCreators(updateBadgeAssignation, dispatch),
  updateSchool: bindActionCreators(updateSchool, dispatch),
  updateUserProfile: bindActionCreators(updateUserProfile, dispatch),
  setBadgeAssignationErrorMessage: bindActionCreators(setBadgeAssignationErrorMessage, dispatch),
  setStudentInformations: bindActionCreators(setStudentInformations, dispatch),
  setStudentExtraInformations: bindActionCreators(setStudentExtraInformations, dispatch),
  fetchStudentExtraInformations: bindActionCreators(fetchStudentExtraInformations, dispatch),
  setStudentPrivacyInformations: bindActionCreators(setStudentPrivacyInformations, dispatch),
  setStudentProfileImage: bindActionCreators(setStudentProfileImage, dispatch),
  fetchStudentProfileImage: bindActionCreators(fetchStudentProfileImage, dispatch),
  fetchSectorQuotas: bindActionCreators(fetchSectorQuotas, dispatch),
  removeQuota: bindActionCreators(removeQuota, dispatch),
  updateQuota: bindActionCreators(updateQuota, dispatch),
  addOrUpdateFreeFieldType: bindActionCreators(addOrUpdateFreeFieldType, dispatch),
  setFreeFieldType: bindActionCreators(setFreeFieldType, dispatch),
  deleteFreeFieldType: bindActionCreators(deleteFreeFieldType, dispatch),
  updateFreeFieldType: bindActionCreators(updateFreeFieldType, dispatch),
  fetchCanSchoolEditSchedulesByInstitution: bindActionCreators(fetchCanSchoolEditSchedulesByInstitution, dispatch),
  setStudentsExtraInformations: bindActionCreators(setStudentsExtraInformations, dispatch),
  fetchStudentExtraInformationsById: bindActionCreators(fetchStudentExtraInformationsById, dispatch),
  setStudentsOptIn: bindActionCreators(setStudentsOptIn, dispatch),
  modifyStudentOptIn: bindActionCreators(modifyStudentOptIn, dispatch),
  setStudentsFreeFields: bindActionCreators(setStudentsFreeFields, dispatch),
  setCanSchoolEditSchedules: bindActionCreators(setCanSchoolEditSchedules, dispatch),
  setInstitutionTemplates: bindActionCreators(setInstitutionTemplates, dispatch),
  fetchInstitutionTemplates: bindActionCreators(fetchInstitutionTemplates, dispatch),
  setInstitutionTemplateVariables: bindActionCreators(setInstitutionTemplateVariables, dispatch),
  setInstitutionTemplatesState: bindActionCreators(setInstitutionTemplatesState, dispatch),
  setInternshipShifts: bindActionCreators(setInternshipShifts, dispatch),
  fetchInternshipShifts: bindActionCreators(fetchInternshipShifts, dispatch),
  addSchoolSection: bindActionCreators(addSchoolSection, dispatch),
  removeSchoolSection: bindActionCreators(removeSchoolSection, dispatch),
  fetchSchoolSectionsBySchool: bindActionCreators(fetchSchoolSectionsBySchool, dispatch),
  modifySchoolSection: bindActionCreators(modifySchoolSection, dispatch),
  setInternshipDocuments: bindActionCreators(setInternshipDocuments, dispatch),
  fetchInternshipDocuments: bindActionCreators(fetchInternshipDocuments, dispatch),
  setMultipleInternshipsShifts: bindActionCreators(setMultipleInternshipsShifts, dispatch),
  fetchMultipleInternshipShifts: bindActionCreators(fetchMultipleInternshipShifts, dispatch),
  addShiftFromPreset: bindActionCreators(addShiftFromPreset, dispatch),
  updateShiftFromPreset: bindActionCreators(updateShiftFromPreset, dispatch),
  updateNissByStudent: bindActionCreators(updateNissByStudent, dispatch),
  acceptDesiderataByUser: bindActionCreators(acceptDesiderataByUser, dispatch),
  removeShift: bindActionCreators(removeShift, dispatch),
  fetchInstitutionQuotas: bindActionCreators(fetchInstitutionQuotas, dispatch),
  fetchSectorsQuotas: bindActionCreators(fetchSectorsQuotas, dispatch),
  fetchSectorsByInstitution: bindActionCreators(fetchSectorsByInstitution, dispatch),
  fetchSectorsFilesCount: bindActionCreators(fetchSectorsFilesCount, dispatch),
  addQuota: bindActionCreators(addQuota, dispatch),
  duplicateQuota: bindActionCreators(duplicateQuota, dispatch),
  fetchPausesQuotasByInstitution: bindActionCreators(fetchPausesQuotasByInstitution, dispatch),
  createPauseQuota: bindActionCreators(createPauseQuota, dispatch),
  updatePauseQuota: bindActionCreators(updatePauseQuota, dispatch),
  deletePauseQuota: bindActionCreators(deletePauseQuota, dispatch),
  setPauseActivationState: bindActionCreators(setPauseActivationState, dispatch),
  fetchAllInstitutionOptionTypes: bindActionCreators(fetchAllInstitutionOptionTypes, dispatch),
  fetchInstitutionOptions: bindActionCreators(fetchInstitutionOptions, dispatch),
  fetchPauseQuotasCountsPerSector: bindActionCreators(fetchPauseQuotasCountsPerSector, dispatch),
  setSelectedInstitution: bindActionCreators(setSelectedInstitution, dispatch),
  setSelectedSector: bindActionCreators(setSelectedSector, dispatch),
  fetchShiftsCountsPerSector: bindActionCreators(fetchShiftsCountsPerSector, dispatch),
  removeStudentProfileImage: bindActionCreators(removeStudentProfileImage, dispatch),
  fetchInstitutionsWithActivatedPauseQuotaOption: bindActionCreators(fetchInstitutionsWithActivatedPauseQuotaOption, dispatch),
  setSectors: bindActionCreators(setSectors, dispatch),
  updateInternshipWithCallback: bindActionCreators(updateInternshipWithCallback, dispatch),
  fetchQuotasTree: bindActionCreators(fetchQuotasTree, dispatch),
  addInstitutionConstraint: bindActionCreators(addInstitutionConstraint, dispatch),
  fetchInstitutionConstraints: bindActionCreators(fetchInstitutionConstraints, dispatch),
  removeInstitutionConstraint: bindActionCreators(removeInstitutionConstraint, dispatch),
  updateInstitutionConstraint: bindActionCreators(updateInstitutionConstraint, dispatch),
  fetchInstitutionShiftsPresets: bindActionCreators(fetchInstitutionShiftsPresets, dispatch),
  fetchAllContactPersonsSection: bindActionCreators(fetchAllContactPersonsSection, dispatch),
  loadMessageFieldsTypes: bindActionCreators(loadMessageFieldsTypes, dispatch),
  removeInternshipsShifts: bindActionCreators(removeInternshipsShifts, dispatch)
})

export { connect }
