import React from 'react'

import { Button, Tooltip } from 'antd'
import moment from 'moment'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CurrentWeekButton = ({ date, loading, title, onClick }) => {
  const currentWeekDate = moment()
    .startOf('isoWeek')
    .hours(0)
    .minutes(0)
    .seconds(0)

  return (
    <Tooltip
      placement='top'
      title={title}
    >
      <Button
        onClick={() => onClick(currentWeekDate)}
        disabled={loading || currentWeekDate.isSame(date)}
      >
        <FontAwesomeIcon icon={faRedo} />
      </Button>
    </Tooltip>
  )
}

export default CurrentWeekButton
