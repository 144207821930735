import { fetch, requestWithPromise } from '..'
import { DELETE, POST } from '../apiHelper'

export const linkSchoolSection = (user, sectorDocument, schoolSections) => {
  return requestWithPromise(
    `/api/sector-documents/${sectorDocument.id}/school-sections`,
    POST,
    { schoolSections },
    user
  )
}

export const deleteSectorDocument = (user, sectorDocument) => {
  return fetch(
    `/api/SectorDocument/${sectorDocument.id}`,
    DELETE,
    null,
    user
  )
}
