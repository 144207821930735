import { createContext, useCallback } from 'react'
import useLocalStorage from '../hooks/UseLocalStorage'
import appConfig from '../config'
import pkceChallenge from 'pkce-challenge'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import routes from '../routes'
import { loginSSO } from '../utils/api/auth'

export const AuthContext = createContext()

const AuthProvider = ({ children, history }) => {
  const [codeVerifier, setCodeVerifier] = useLocalStorage('code_verifier', null)
  const [, setAccountId] = useLocalStorage('account_id', null)
  /**
   * After Auth callback, exchange the authorization code for a token
   */
  const authCallback = useCallback(
    async (authorizationCode: string, onGetUserContextAndData: function, onAfterLogin: function) => {
      const data = await loginSSO(authorizationCode, codeVerifier)

      if (!data) {
        throw new Error('Error getting token')
      }

      setAccountId(data.accountId)

      onGetUserContextAndData(data.token).then(() => {
        onAfterLogin()
      })
    },
    []
  )

  const createCodeChallenge = async () => {
    const challenge = await pkceChallenge()

    setCodeVerifier(challenge.code_verifier)

    return challenge.code_challenge
  }

  const redirectToSSOLoginPage = async (email = null) => {
    const challenge = await createCodeChallenge()

    let url = `${appConfig.opalAuthServeurWebapp}?${appConfig.redirectUrl}&code_challenge=${challenge}`

    if (email) {
      url += `&email=${email}`
    }

    window.location.href = url
  }

  const navigateToLoginPage = async () => {
    history.push(routes.LOGIN)
  }

  return (
    <AuthContext.Provider
      value={{
        authCallback,
        createCodeChallenge,
        redirectToSSOLoginPage,
        navigateToLoginPage
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default withRouter(AuthProvider)
