import React, { useContext, useMemo } from 'react'

import { connect } from 'react-redux'
import InternshipsTableHead from './InternshipsTableHead'
import { InternshipsManagerContext } from '../../../Context/InternshipsManager/InternshipsManagerProvider'
import { getTranslate } from 'react-localize-redux'
import InternshipsTableBody from './InternshipsTableBody'
import Cookies from 'js-cookie'
import { getUser } from '../../../reducers/UserReducer'
import { INSTITUTION_CONTEXT } from '../../../utils/constants'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InternshipsTable = ({ loading, user }) => {
  const { getDataReady, onTableLeave } = useContext(InternshipsManagerContext)

  const height = useMemo(() => {
    if (user.context === INSTITUTION_CONTEXT) {
      return `calc(100vh - ${Cookies.get('switch_user_id') ? '242px' : '201px'})`
    } else {
      return `calc(100vh - ${Cookies.get('switch_user_id') ? '177px' : '136px'})`
    }
  }, [user.context, Cookies.get('switch_user_id')])

  return (
    <div className='internship-manager-table' style={{ height }}>
      <table className='internships-manager' onMouseLeave={onTableLeave}>
        <InternshipsTableHead />
        <InternshipsTableBody
          loading={loading || !getDataReady.schools || !getDataReady.institutions || !getDataReady.students}
        />
      </table>
    </div>
  )
}

export default connect(mapStateToProps)(InternshipsTable)
