import React, { useContext, useEffect, useMemo } from 'react'

import { Switch } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faChevronDown, faClinicMedical, faGraduationCap, faSchool } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { EUROPEAN_DATE, ORDER_DIRECTIONS, SCHOOL_CONTEXT } from '../../utils/constants'
import { getUser } from '../../reducers/UserReducer'
import useLocalStorage from '../../hooks/UseLocalStorage'
import IconButton from '../antd/Buttons/IconButton'
import { getTranslate } from 'react-localize-redux'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { GlobalContext } from '../../Providers/GlobalProvider'
import { InternshipMessageContext } from '../../Providers/InternshipMessageProvider'
import { getTimezoneLessMoment } from '../../utils/momentjs'
import Loading from '../../HOC/Loading'
import InfiniteScroll from '../../HOC/InfiniteScroll'

import '../../assets/message-box.scss'

const LOCAL_STORAGE_SETTINGS_KEY = 'internshipMessagesBox'

const mapStateToProps = state => ({
  user: getUser(state.getUser),
  t: getTranslate(state.locale)
})

const MessageBox = ({ user, t }) => {
  const { unreadMessages } = useContext(GlobalContext)
  const { display, internshipsMessages, setDisplay, setSelectedInternship } = useContext(InternshipMessageContext)

  const [settings, setSettings] = useLocalStorage(LOCAL_STORAGE_SETTINGS_KEY, { unread: false, orderDirection: ORDER_DIRECTIONS.DESC })
  const isSchoolUser = useMemo(() => user.context === SCHOOL_CONTEXT, [user])

  useEffect(() => {
    internshipsMessages.setParameters(settings)
  }, [settings, internshipsMessages.setParameters])

  const messages = useMemo(() => {
    let data = []

    Object.keys(internshipsMessages.all).forEach(key => {
      data = data.concat(internshipsMessages.all[key])
    })

    return data
  }, [internshipsMessages.all])

  const handleBottomReach = internshipsMessages.next

  const messagesListJSX = useMemo(() => messages.map((item, index) => (
    <li key={`${item.id}-${index}`} onClick={() => setSelectedInternship(item)}>
      <div className={`message-item ${item.unreadMessages ? 'new-messages' : ''}`}>
        <div className='message-text'>
          <div className='inner-message-row'>
            <span className='message-icon'>
              <FontAwesomeIcon icon={isSchoolUser ? faClinicMedical : faSchool} />
            </span>
            <span className='inner-message-text'>{isSchoolUser ? item.institution.name : item.school.name}</span>
          </div>
          <div className='inner-message-row'>
            <span className='message-icon'>
              <FontAwesomeIcon icon={faGraduationCap} />
            </span>
            <span className='inner-message-text'>{item.student === null ? t('Without student') : `${item.student.firstname} ${item.student.lastname}`}</span>
          </div>
          <div className='inner-message-row'>
            <span className='message-icon'>
              <FontAwesomeIcon icon={faCalendar} />
            </span>
            <span className='inner-message-text'>{getTimezoneLessMoment(item.startDate).format(EUROPEAN_DATE)} - {getTimezoneLessMoment(item.endDate).format(EUROPEAN_DATE)}</span>
          </div>
        </div>
        {item.unreadMessages > 0 && (
          <div className='messages-count'>
            <span className='message-count-circle'>{item.unreadMessages}</span>
          </div>
        )}
      </div>
    </li>
  )), [messages, isSchoolUser, t, setSelectedInternship])

  return (
    <div className={`message-box ${display ? 'displayed' : ''}`}>
      <div className='message-box-header' onClick={() => setDisplay(false)}>
        <div className='title'>
          {t('Messaging')}
          {unreadMessages > 0 && <span className='message-count-circle'>{unreadMessages > 999 ? '999+' : unreadMessages}</span>}
        </div>
        <span>
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </div>
      <div className='filter-box-wrapper'>
        <div className='option-group'>
          <label>{t('Show only unread messages')}</label>
          <Switch checked={settings.unread} onChange={() => setSettings({ ...settings, unread: !settings.unread })} />
        </div>
        <div className='option-group'>
          <label>{t('Sorting by date')}</label>
          <IconButton
            className={`sort-button ${settings.orderDirection === ORDER_DIRECTIONS.ASC ? 'up' : ''}`}
            icon={faArrowDown}
            onClick={() => setSettings({ ...settings, orderDirection: settings.orderDirection === ORDER_DIRECTIONS.ASC ? ORDER_DIRECTIONS.DESC : ORDER_DIRECTIONS.ASC })}
          />
        </div>
      </div>
      <div className='separation-line' />
      <div className='messages-container'>
        {internshipsMessages.data.length > 0 && (
          <InfiniteScroll
            onBottomReach={handleBottomReach}
            hasMore={!internshipsMessages.isLastPage}
            loading={internshipsMessages.loading}
            loadingChild={<Loading loading />}
          >
            <ul className='messages-list'>
              {messagesListJSX}
              {internshipsMessages.loading && <Loading loading />}
            </ul>
          </InfiniteScroll>
        )}
        <Loading loading={internshipsMessages.loading}>
          {internshipsMessages.data.length === 0 && (
            <div className='no-message-notification'>
              {t(settings.showOnlyUnreadMessages ? 'You don\'t have unread messages' : 'You don\'t have messages')}
            </div>
          )}
        </Loading>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(MessageBox)
