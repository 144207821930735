import React, { useContext, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip, Popover, Button } from 'antd'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { readAllUserMessages } from '../../../utils/api/user'
import { TOOLTIP_TRIGGERS } from '../../antd/antdHelper'

import '../../../assets/new-messages-notification.scss'
import { InternshipMessageContext } from '../../../Providers/InternshipMessageProvider'

const mapStateToProps = state => ({ user: getUser(state.getUser), t: getTranslate(state.locale) })

const NewMessagesNotification = ({ t, user }) => {
  const { unreadMessages, setUnreadMessages } = useContext(GlobalContext)
  const { setDisplay } = useContext(InternshipMessageContext)

  const [loading, setLoading] = useState(false)

  const markAllAsRead = async () => {
    setLoading(true)
    setUnreadMessages(0)

    readAllUserMessages(user).then(() => setLoading(false))
  }

  return (
    <Popover
      placement='right'
      trigger={unreadMessages === 0 ? TOOLTIP_TRIGGERS.CONTEXT_MENU : TOOLTIP_TRIGGERS.HOVER}
      content={(
        <Button
          loading={loading}
          onClick={markAllAsRead}
          type='primary'
        >
          {t('new_messages_notification.popover')}
        </Button>
      )}
    >
      <Tooltip placement='right' title={t('new_messages_notification.tooltip')} trigger={unreadMessages > 0 ? TOOLTIP_TRIGGERS.CONTEXT_MENU : TOOLTIP_TRIGGERS.HOVER}>
        <button
          className={`new-messages-notification-wrapper ${unreadMessages > 999 ? 'small-text' : ''} ${unreadMessages === 0 ? 'disabled' : ''}`}
          type='button' onClick={() => setDisplay(true)}
        >
          <FontAwesomeIcon icon={faBell} />
          {unreadMessages > 0 && <div className='unread-messages'>{unreadMessages > 999 ? '999+' : unreadMessages}</div>}
        </button>
      </Tooltip>
    </Popover>
  )
}

export default connect(mapStateToProps)(NewMessagesNotification)
