import React, { useState, useRef } from 'react'
import { Button, DatePicker, Checkbox, Modal, Card, Row, Col, Switch, Tooltip } from 'antd'
import { downloadFile, generalErrorHandler, request, requestWithPromise } from '../../utils'
import locale from 'antd/es/date-picker/locale/fr_FR'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { getActiveLanguage, getTranslate } from 'react-localize-redux'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { GET, POST } from '../../utils/apiHelper'
import { faExclamationTriangle, faFileExport, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { EUROPEAN_DATE } from '../../utils/momentjs'
import { faFrown, faMeh, faSmile } from '@fortawesome/free-regular-svg-icons'
import { FILLED_COLOR } from './ShiftsManager/constants'

import '../../assets/evaluation-exports-button.scss'

const { RangePicker } = DatePicker
const EXPORT_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const mapStateToProps = state => {
  return {
    currentLanguage: getActiveLanguage(state.locale).code,
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const EvaluationsExportButton = ({ currentLanguage, user, t }) => {
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'))
  const [endDate, setEndDate] = useState(moment())
  const [selectedFilters, setSelectedFilters] = useState({ institution: [true, false, false], student: [true, false, false] })
  const [selectedYears, setSelectedYears] = useState([1])
  const [rgpdRestrictedExport, setRgpdRestrictedExport] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const downloadPollingInterval = useRef(null)

  const checkboxOptions = [
    { label: t('Last year'), value: 1 },
    { label: t('Second to last year'), value: 2 },
    { label: t('Other years'), value: 0 }
  ]

  const handleExport = () => {
    handleCloseModal()
    setLoading(true)

    const body = {
      startDate: startDate.format(EXPORT_FORMAT),
      endDate: endDate.format(EXPORT_FORMAT),
      language: currentLanguage,
      sharedData: rgpdRestrictedExport,
      institutions: user.institutions.map(i => i.id)
    }

    let institutionSatisfaction = ''
    let studentSatisfaction = ''

    selectedFilters.institution.forEach(v => {
      institutionSatisfaction += v ? '1' : '0'
    })

    selectedFilters.student.forEach(v => {
      studentSatisfaction += v ? '1' : '0'
    })

    if (institutionSatisfaction !== '000') {
      body.institutionSatisfaction = institutionSatisfaction
    }

    if (studentSatisfaction !== '000') {
      body.studentSatisfaction = studentSatisfaction
    }

    if (selectedYears.length > 0) {
      body.years = selectedYears.toString()
    }

    requestWithPromise(
      '/export/attractivity-module',
      POST,
      body,
      user
    ).then(json => {
      if (json.status !== 'error') {
        downloadPollingInterval.current = setInterval(() => {
          request('/job-result/' + json.job, GET, null, user).then(json => {
            clearInterval(downloadPollingInterval.current)

            if (json.data) {
              const fileName = json.data.result

              try {
                downloadFile(
                  '/spreadsheet/download/' + fileName,
                  fileName,
                  user,
                  GET
                ).then(() => setLoading(false))
              } catch (err) {
                generalErrorHandler(err)
                setLoading(false)
              }
            }
          })
        }, 1500)
      }
    })
  }

  const handleFilterCheck = (key, index) => {
    const newFilters = { ...selectedFilters }
    const newChecks = [...selectedFilters[key]]

    newChecks[index] = !newChecks[index]
    newFilters[key] = newChecks

    setSelectedFilters(newFilters)
  }

  const handleCloseModal = () => {
    setIsVisible(false)
    setStartDate(moment().subtract(1, 'months'))
    setEndDate(moment())
    setSelectedFilters({ institution: [true, false, false], student: [true, false, false] })
    setSelectedYears([1])
    setRgpdRestrictedExport(true)
  }

  return (
    <>
      <Button
        type='default'
        onClick={() => setIsVisible(true)}
        disabled={loading}
      >
        {loading && <FontAwesomeIcon icon={faSpinner} spin size='1x' />}
        {!loading && <FontAwesomeIcon icon={faFileExport} />}
        &nbsp; {t(loading ? 'misc.loading' : 'Evaluations export')}
      </Button>
      <Modal
        title={t('Export internship evaluations')}
        visible={isVisible}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        onCancel={handleCloseModal}
        footer={[
          <Button key='back' onClick={handleCloseModal}>
            {t('Cancel')}
          </Button>,
          <Button
            key='front'
            type='primary'
            onClick={handleExport}
            disabled={loading}
          >
            <FontAwesomeIcon icon='file-export' />&nbsp;
            {t('Export')}
          </Button>
        ]}
        destroyOnClose
      >
        <div className='evaluation-exports-body'>
          <h3><u> {t('Export period')} </u></h3>
          <RangePicker
            locale={locale}
            onChange={dates => {
              setStartDate(dates[0].clone())
              setEndDate(dates[1].clone())
            }}
            value={[startDate, endDate]}
            format={EUROPEAN_DATE}
          />
          <h3><u> {t('evaluation_export_button.satisfaction_filter_title')} </u></h3>
          <Card>
            <Row>
              <Col span={12}>
                <div className='evaluation-exports-filters-title'>
                  <h4> {t('Institution')} </h4>
                </div>
                <div className='evaluation-exports-filters-body'>
                  <button
                    type='button'
                    className={selectedFilters.institution[0] ? 'positive-selected' : ''}
                    onClick={() => handleFilterCheck('institution', 0)}
                  >
                    <FontAwesomeIcon icon={faSmile} size='1x' />
                  </button>
                  <button
                    type='button'
                    className={selectedFilters.institution[1] ? 'medium-selected' : ''}
                    onClick={() => handleFilterCheck('institution', 1)}
                  >
                    <FontAwesomeIcon icon={faMeh} size='1x' />
                  </button>
                  <button
                    type='button'
                    className={selectedFilters.institution[2] ? 'negative-selected' : ''}
                    onClick={() => handleFilterCheck('institution', 2)}
                  >
                    <FontAwesomeIcon icon={faFrown} size='1x' />
                  </button>
                </div>
              </Col>
              <Col span={12}>
                <div className='evaluation-exports-filters-title'>
                  <h4> {t('Student')} </h4>
                </div>
                <div className='evaluation-exports-filters-body'>
                  <button
                    type='button'
                    className={selectedFilters.student[0] ? 'positive-selected' : ''}
                    onClick={() => handleFilterCheck('student', 0)}
                  >
                    <FontAwesomeIcon icon={faSmile} />
                  </button>
                  <button
                    type='button'
                    className={selectedFilters.student[1] ? 'medium-selected' : ''}
                    onClick={() => handleFilterCheck('student', 1)}
                  >
                    <FontAwesomeIcon icon={faMeh} />
                  </button>
                  <button
                    type='button'
                    className={selectedFilters.student[2] ? 'negative-selected' : ''}
                    onClick={() => handleFilterCheck('student', 2)}
                  >
                    <FontAwesomeIcon icon={faFrown} />
                  </button>
                </div>
              </Col>
            </Row>
          </Card>
          <div className='h-spacing' />
          <h3><u> {t('Internship years filter')} </u></h3>
          <Checkbox.Group
            defaultValue={[1]}
            value={selectedYears}
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            onChange={setSelectedYears}
          >
            <Row>
              {checkboxOptions.map((o, index) => {
                return (
                  <Col key={'col_' + index} span={12}>
                    <Checkbox value={o.value}>
                      {o.label}
                    </Checkbox>
                  </Col>
                )
              })}
            </Row>
          </Checkbox.Group>
          <h3><u> {t('Data sharing filter')} </u></h3>
          <div className='data-sharing-filter'>
            <span className={rgpdRestrictedExport ? 'green-checked' : ''}> {t('Shared data only')} </span>
            <Switch onChange={checked => setRgpdRestrictedExport(!checked)} />
            <span className={!rgpdRestrictedExport ? 'orange-checked' : ''}> {t('All students')} </span>
            <Tooltip title={t('For RGPD reasons, you cannot contact the student for recruitment purposes if the last column of the export is set to "no"')}>
              <FontAwesomeIcon icon={faExclamationTriangle} color={FILLED_COLOR} />
            </Tooltip>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default connect(mapStateToProps)(EvaluationsExportButton)
