import React, { useCallback, useEffect, useState } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../../reducers/UserReducer'
import { Drawer } from 'antd'
import SectorsTable from './SectorsTable'
import { Metadata, retrieveMetadata } from '../../../utils/http'
import { ORDER_BY } from '../../../utils/constants'
import { TableParameters } from '../../../utils/entities/tableParameters'
import { debounce } from 'lodash'
import { getInstitutionSectors } from '../../../utils/api/institution'
import { ACTIONS } from '../../../Components/shared/DataTable/utils/actions'
import IconTooltip from '../../../Components/institution/Quotas/definition/IconTooltip'
import { faClinicMedical } from '@fortawesome/free-solid-svg-icons'
import { COLORS_PALETTE } from '../../../config/colors'
import SectorFormModal from '../../../HOC/Forms/SectorFormModal'
import { archiveSector } from '../../../utils/api/sector'
import { onSuccess } from '../../../utils/apiHelper'
import SwitchSectorInstitutionModal from '../../../HOC/SwitchSectorInstitutionModal'

const DEFAULT_DATA = { data: [], meta: new Metadata({ pageSize: 20 }) }

const mapStateToProps = state => ({
  t: getTranslate(state.locale),
  user: getUser(state.getUser)
})

const InstitutionSectorsDrawer = ({ institution, user, width = 600, onClose, onTriggerAction, t }) => {
  const [sectors, setSectors] = useState(DEFAULT_DATA)
  const [tableParameters, setTableParameters] = useState(new TableParameters({ orderBy: ORDER_BY.NAME, pageSize: 20 }))
  const [loading, setLoading] = useState(true)

  const fetchSectors = useCallback((institution, user, tableParameters) => {
    if (institution) {
      setLoading(true)

      getInstitutionSectors(user, institution, { ...tableParameters, archived: false }).then(json => {
        if (json?.data || json?.meta) {
          const sectors = {}

          sectors.data = json?.data ?? []
          sectors.meta = json?.meta ? retrieveMetadata(json.meta) : new Metadata()

          setSectors(sectors)
        }

        setLoading(false)
      })
    }
  }, [getInstitutionSectors, setLoading, setSectors])

  const handleArchiveSector = useCallback((sector, archive) => {
    archiveSector(user, sector, archive).then(json => {
      if (json?.data) {
        onSuccess(t('institution_sector_drawer.archive.success'))
        fetchSectors(institution, user, tableParameters)
      }
    })
  }, [user, institution, tableParameters, onSuccess, archiveSector, fetchSectors, t])

  const handleActionTrigger = useCallback((sector, action) => {
    if (action === ACTIONS.ARCHIVE_SECTOR) {
      handleArchiveSector(sector, true)
    } else if (action === ACTIONS.UNARCHIVE_SECTOR) {
      handleArchiveSector(sector, false)
    } else if (action === ACTIONS.DELETE) {
      // block the action bubulling
    } else if (typeof onTriggerAction === 'function') {
      onTriggerAction(sector, action)
    }
  }, [handleArchiveSector])

  useEffect(() => {
    const fetch = debounce(() => fetchSectors(institution, user, tableParameters), 300)

    fetch()

    return () => {
      fetch.cancel()
    }
  }, [institution, user.id, tableParameters, fetchSectors])

  return (
    <Drawer
      title={<div><IconTooltip icon={faClinicMedical} iconColor={COLORS_PALETTE.SECOND} title={institution?.name ?? ''} /> {t('instition_sectors_drawer.title')}</div>}
      visible={!!institution}
      width={width}
      onClose={() => {
        setSectors(DEFAULT_DATA)

        if (typeof onClose === 'function') {
          onClose()
        }
      }}

    >
      <SectorFormModal institutions={[institution]} onTriggerAction={handleActionTrigger} onSave={() => fetchSectors(institution, user, tableParameters)}>
        <SwitchSectorInstitutionModal onSave={() => fetchSectors(institution, user, tableParameters)}>
          <SectorsTable
            data={sectors.data}
            institution={institution}
            loading={loading}
            metadata={sectors.meta}
            parameters={tableParameters}
            onParametersChange={setTableParameters}
          />
        </SwitchSectorInstitutionModal>
      </SectorFormModal>
    </Drawer>
  )
}

export default connect(mapStateToProps)(InstitutionSectorsDrawer)
