import React, { useEffect } from 'react'

import { faSitemap } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tabs } from 'antd'
import { getTranslate } from 'react-localize-redux'
import Students from '../../Components/school/Students/Students'
import SupervisedStudents from '../../Components/school/Students/SupervisedStudents'
import { connect } from '../../reducers/Dispatchers'
import { getUser } from '../../reducers/UserReducer'
import Analytics from '../../utils/analytics'
import { isSupervisor } from '../../utils/roles'

const { TabPane } = Tabs

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const SchoolStudentsView = props => {
  const userIsSupervisor = isSupervisor(props.getUser)

  useEffect(() => Analytics.pageView('/school-students'), [])

  const render = () => {
    return (
      <Tabs defaultActiveKey='1' animated={false}>
        <TabPane
          key='1'
          tab={
            <div className='flex-row'>
              <FontAwesomeIcon icon='user-graduate' style={{ marginTop: '3px' }} />
              <div className='h-spacing' />
              {props.t('School students')}
            </div>
          }
        >
          <Students />
        </TabPane>
        {userIsSupervisor &&
          <TabPane
            key='2'
            tab={
              <div className='flex-row'>
                <FontAwesomeIcon icon={faSitemap} style={{ marginTop: '3px' }} />
                <div className='h-spacing' />
                {props.t('My students')}
              </div>
            }
          >
            <SupervisedStudents />
          </TabPane>}
      </Tabs>
    )
  }

  return (
    render()
  )
}

export default connect(mapStateToProps)(SchoolStudentsView)
