import React, { Suspense } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import routes from '../routes'
import ChangeLogView from '../Views/shared/ChangeLogView'
import InstitutionEvaluationSummaryView from '../Views/institution/InstitutionEvaluationSummaryView'
import ReportingView from '../Views/shared/ReportingView'
import GroupsSettingsView from '../Views/institution/Settings/GroupsSettingsView'
import InstitutionGroupProvider from '../Providers/InstitutionGroupProvider'
import SchoolsView from '../Views/institutionGroup/SchoolsView/SchoolsView'
import InstitutionsView from '../Views/institutionGroup/InstitutionsView/InstitutionsView'
import SectorsView from '../Views/institutionGroup/SectorsView/SectorsView'
import StudentsView from '../Views/institution/StudentsView'
import InternshipsView from '../Views/institution/InternshipsView/InternshipsView'
import InternshipMessageProvider from '../Providers/InternshipMessageProvider'

const InstitutionGroupAdminRouter = ({ history, moduleOption }) => {
  return (
    <InstitutionGroupProvider>
      <InternshipMessageProvider>
        <Switch>
          <Route path={routes.INTERNSHIPS}>
            <InternshipsView />
          </Route>
          <Route path={routes.STUDENTS}>
            <StudentsView />
          </Route>
          <Route path={routes.SETTINGS}>
            <GroupsSettingsView />
          </Route>
          <Route path={routes.BADGES}>
            <ReportingView />
          </Route>
          <Route path={routes.SCHOOLS}>
            <SchoolsView />
          </Route>
          <Route path={routes.INSTITUTIONS}>
            <InstitutionsView />
          </Route>
          <Route path={routes.INSTITUTION_SECTORS}>
            <SectorsView />
          </Route>
          <Route path={routes.CHANGELOG}>
            <ChangeLogView />
          </Route>
          <Route path={routes.STUDENTS_EVALUATION}>
            <Suspense fallback={<div>Loading...</div>}>
              <InstitutionEvaluationSummaryView />
            </Suspense>
          </Route>
        </Switch>
      </InternshipMessageProvider>
    </InstitutionGroupProvider>
  )
}

export default withRouter(InstitutionGroupAdminRouter)
