import React from 'react'
import { Result } from 'antd'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  t: getTranslate(state.locale)
})

const Error500 = ({ extra, t }) => {
  return (
    <Result
      status='500'
      title={t('error_500.title')}
      subTitle={t('error_500.subtitle')}
      extra={extra}
    />
  )
}

export default connect(mapStateToProps)(Error500)
