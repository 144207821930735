import React, { useContext, useEffect } from 'react'
import InternshipsManagerView from '../shared/InternshipsManagerView'
import { InternshipMessageContext } from '../../Providers/InternshipMessageProvider'

const InternshipsView = ({ loadShiftsManager }) => {
  const { setTrackedInternships } = useContext(InternshipMessageContext)

  useEffect(() => () => setTrackedInternships([]), [])

  return (<InternshipsManagerView loadShiftsManager={loadShiftsManager} />)
}

export default InternshipsView
