
import React, { useState, useEffect, useContext } from 'react'

import FilledShiftCell from './FilledShiftCell'
import { ABSENCE_CATEGORY, CELL_TRESHOLDS, MULTI_SELECT_MODE } from '../constants'
import { mapStateToProps, mapDispatchToProps, connect } from '../../../../reducers/Dispatchers'
import moment from 'moment'
import { makeNewShift } from '../../../../utils/entities/entityFactory'
import { isCustomTime } from '../../../../utils/typeTesting'
import { getMultiSelectionShiftEncapsulation } from '../../../../utils/entities/ShiftEncapsulation'
import { getDisplayTime, getIsoTime, makeUrlBody, setEventCodeWithType } from '../helper'
import { DATE_FORMAT_API, SCHOOL_CONTEXT } from '../../../../utils/constants'
import PauseQuotaCell from './PauseQuotaCell'
import { computeCellRemainingPlace, computeCellTreshold } from '../Other/utils'
import { useDispatch } from 'react-redux'
import { SHIFT_MANAGER } from '../../../../reducers/ShiftsManagerReducer/actionsType'
import { GlobalContext } from '../../../../Providers/GlobalProvider'
import { isObject } from '../../../../utils'
import { isEmpty } from 'lodash'
import { sendInternshipMessage } from '../../../../utils/api/internshipMessage'

const { SET_DISPLAY_EXCEEDING_MODAL } = SHIFT_MANAGER

const EXCEEDING_QUOTA_MESSAGE = 'This internship has exceeded the quota of places defined on '
const EXCEEDING_QUOTA_DATE_FORMAT = 'DD/MM/YYYY'
export const EVENT_CODE_ERROR_MESSAGE = 'The event code must be one of :'
const COLORS = {
  m: '#fcebba',
  s: '#f4a264',
  '/': '#beb3b2',
  v: '#add8e6'
}
const { SINGLE } = MULTI_SELECT_MODE

const ShiftCell = (props) => {
  const { eventCodes } = useContext(GlobalContext)

  const [shift, setShift] = useState({})
  const [className, setClassName] = useState('')
  const [shiftColor, setShiftColor] = useState(null)
  const [preset, setPreset] = useState(null)
  const [internship, setInternship] = useState({})
  const [formattedStartTime, setFormattedStartTime] = useState('')
  const [formattedEndTime, setFormattedEndTime] = useState('')
  const [displayEvent, setDisplayEvent] = useState(false)
  const [shiftErrorMessage, setShiftErrorMessage] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    setShift(setEventCodeWithType(props.currentShift, eventCodes))
    setShiftErrorMessage(null)

    if (!props.currentShift.id) {
      setShiftColor(null)
      setPreset(null)
    }
  }, [props.currentShift, eventCodes])

  useEffect(() => {
    if (shift.id) {
      setFormattedStartTime(shift.startTime
        ? getDisplayTime(shift.startTime)
        : moment(shift.startDate).format('HH:mm'))
      setFormattedEndTime(shift.endTime
        ? getDisplayTime(shift.endTime)
        : moment(shift.endDate).format('HH:mm'))
    } else {
      setFormattedStartTime('')
      setFormattedEndTime('')
    }
  }, [shift])

  useEffect(() => {
    if (internship && shift.id) {
      const internshipPresets = internship.presets ?? []
      const currentPreset = getCurrentPreset(internshipPresets, shift)

      setPreset(currentPreset)
      setShiftColor(getShiftColor(shift, currentPreset))
    } else {
      setShiftColor(null)
    }
  }, [shift, internship])

  useEffect(() => {
    setClassName(chooseClassNameWithShift(shift, internship, props.currentDay))
  }, [props.currentDay, shift, internship])

  useEffect(() => {
    if (props.internship) {
      setInternship(props.internship)
    }
  }, [props.internship])

  const getCurrentPreset = (presetsList, shift) => {
    const shiftPeriodCode = shift.periodCode
      ? shift.periodCode.toLowerCase()
      : ''

    return presetsList.find(preset => {
      return preset.periodCode.toLowerCase() === shiftPeriodCode
    })
  }

  const getShiftColor = (shift, preset) => {
    if (!shift.periodCode) {
      return ''
    }

    if (preset && preset.color) {
      return preset.color
    }

    return getDefaultColorByPeriodCodeOrNull(shift.periodCode.toLowerCase())
  }

  const getDefaultColorByPeriodCodeOrNull = periodCode => {
    return COLORS[periodCode] ?? null
  }

  const chooseClassNameWithShift = (shift, internship, currentDay) => {
    if (!currentDay || !internship.startDate || !internship.endDate) {
      return ''
    }

    const currentShiftDay = moment(currentDay.format(DATE_FORMAT_API)).startOf('day')
    const internshipStartMoment = moment(internship.startDate).startOf('day')
    const internshipEndMoment = moment(internship.endDate).startOf('day')
    let className = ''

    if (shift.eventCodeType && shift.eventCodeType.category === ABSENCE_CATEGORY) {
      className = 'shift-absence'
    }

    if (props.isSelected) {
      className = 'selected'
    }

    if ([0, 6].includes(currentShiftDay.day())) {
      className = `${className} in-weekend`.trim()
    }

    if (props.shiftDisabled) {
      className = `${className} disabled`.trim()
    }

    if (internshipStartMoment.diff(currentShiftDay, 'day') === 0) {
      className += (props.inDraftMode ? ' has-internship-draft has-internship-start-draft' : ' has-internship has-internship-start')
    } else if (
      internshipStartMoment < currentShiftDay &&
      currentShiftDay < internshipEndMoment
    ) {
      className += (props.inDraftMode ? ' has-internship-draft' : ' has-internship')
    } else if (internshipEndMoment.diff(currentShiftDay, 'day') === 0) {
      className += (props.inDraftMode ? ' has-internship-draft has-internship-end-draft' : ' has-internship has-internship-end')
    }

    return className.trim()
  }

  const handleSave = (e) => {
    const {
      originaleventcode,
      originalperiodcode
    } = e.currentTarget.dataset
    const periodCode = e.currentTarget.innerHTML.replace(/<br>|&nbsp;/gi, '').trim().toUpperCase()

    if (originalperiodcode === periodCode) {
      if (e.currentTarget.innerHTML.includes('&nbsp;')) {
        e.currentTarget.innerHTML = periodCode
      }

      setShiftErrorMessage(null)

      return
    }

    if (periodCode === '') {
      if (shift.id) {
        removeShift(internship, shift)
      }

      if (shiftErrorMessage) {
        setShiftErrorMessage(null)
      }

      return
    }

    if (!isCorrectCode(periodCode)) {
      revertCodeToOriginalValue(e)
      setShiftErrorMessage('The preset code must be one of :')

      return
    }

    if (shiftErrorMessage) {
      setShiftErrorMessage(null)
    }

    const eventcodeType = eventCodes.find(e => props.t('event.code.' + e.type) === originaleventcode)

    saveCell(
      periodCode ?? '',
      internship,
      shift,
      eventcodeType
    )
  }

  const revertCodeToOriginalValue = e => {
    e.currentTarget.innerHTML = e.currentTarget.dataset.originalperiodcode ?? ''
  }

  const handleSaveEventCode = e => {
    const {
      originaleventcode,
      originalperiodcode
    } = e.currentTarget.dataset
    const eventCode = e.currentTarget.textContent.replace(/&nbsp;/gi, '').trim().toUpperCase()

    e.currentTarget.innerHTML = eventCode

    if (!eventCode) {
      manageEmptyEventCode(originalperiodcode, shift, internship)

      return
    }

    if (eventCode === originaleventcode) {
      return
    }

    if (eventCode && !isCorrectEventCode(eventCode)) {
      e.currentTarget.innerHTML = originaleventcode ?? ''
      setShiftErrorMessage(EVENT_CODE_ERROR_MESSAGE)

      return
    }

    if (shiftErrorMessage) {
      setShiftErrorMessage(null)
    }

    const eventCodeType = eventCodes.find(e => props.t('event.code.' + e.type) === eventCode)

    saveCell(
      originalperiodcode,
      internship,
      shift,
      eventCodeType,
      getIsoTime(formattedStartTime),
      getIsoTime(formattedEndTime)
    )
  }

  const manageEmptyEventCode = (originalperiodcode, shift, internship) => {
    !originalperiodcode
      ? removeShift(internship, shift)
      : saveCell(
        originalperiodcode,
        internship,
        shift,
        '',
        getIsoTime(formattedStartTime),
        getIsoTime(formattedEndTime)
      )

    if (shiftErrorMessage) {
      setShiftErrorMessage(null)
    }
  }

  const isCorrectCode = periodCode => {
    if (!internship || !internship.presets) {
      return false
    }

    return internship.presets.find(
      item => item.periodCode === periodCode
    )
  }

  const isCorrectEventCode = eventCode => {
    return eventCode === '' || eventCodes.map(e => props.t('event.code.' + e.type)).includes(eventCode)
  }

  const isCorrectEventCodeType = eventCodeType => {
    for (const eventCode of eventCodes) {
      if (eventCodeType.type === eventCode.type) {
        return true
      }
    }

    return false
  }

  const saveCell = (periodCode, internship, shift, eventCodeType, startTime, endTime, isDesiderata) => {
    const urlBody = makeUrlBody(
      periodCode,
      internship.id,
      shift,
      eventCodeType,
      startTime,
      endTime,
      isDesiderata
    )
    if (periodCode === '') {
      delete urlBody.periodCode
    }

    shift.id ? updateShift(urlBody) : createShift(urlBody)
  }

  const updateShift = preset => {
    const shiftPreset = internship.presets.find(item => preset.periodCode === item.periodCode)
    const currentDateString = props.currentDay.format(DATE_FORMAT_API)
    const startDate = shiftPreset && shiftPreset.startTime
      ? moment(currentDateString + ' ' + shiftPreset.startTime)
      : shift.startDate
    const endDate = shiftPreset && shiftPreset.endTime
      ? moment(currentDateString + ' ' + shiftPreset.endTime)
      : shift.endDate

    setShift({
      ...shift,
      startDate: startDate,
      endDate: endDate,
      pause: preset.pause,
      periodCode: preset.periodCode,
      eventCodeType: preset.eventCodeType
    })

    if (shift.id > 0) {
      props.updateShiftFromPreset(preset, props.getUser)
    }
  }

  const createShift = preset => {
    const shiftPreset = internship.presets.find(item => preset.periodCode === item.periodCode)
    const currentDateString = props.currentDay.format(DATE_FORMAT_API)
    const startDate = shiftPreset && shiftPreset.startTime
      ? moment(currentDateString + ' ' + shiftPreset.startTime)
      : shift.startDate
    const endDate = shiftPreset && shiftPreset.endTime
      ? moment(currentDateString + ' ' + shiftPreset.endTime)
      : shift.endDate

    preset.startDate = currentDateString

    setShift(makeNewShift(
      internship.id, startDate, endDate, preset.periodCode, preset.pause, preset.eventCodeType, preset.startTime, preset.endTime
    ))
    props.addShiftFromPreset(preset, props.getUser)
  }

  const removeShift = () => {
    setShift({})
    setShiftColor(null)
    setPreset(null)

    if (shift.id && shift.id > 0) {
      const index = isObject(props.getSelectedSector) && props.getSelectedSector.id ? props.columnIndex : -1

      props.removeShift(shift, internship.id, props.getUser, index, preset)
    }
  }

  const handleTimeChange = (e, timePeriod) => {
    const time = e.currentTarget.innerText
    const hoursAndMinutes = time.split(':')
    const supportedKeys = ['ArrowUp', 'ArrowDown']
    const modifyByKey = supportedKeys.includes(e.key)

    if (modifyByKey && isCustomTime(time)) {
      const hours = changeTimeByKey(e.key, +hoursAndMinutes[0])

      e.currentTarget.innerText = `${hoursToString(hours)}:${hoursAndMinutes[1]}`
    }
  }

  const changeTimeByKey = (key, hours) => {
    const actionsByKey = {
      ArrowUp: hours => {
        return hours === 23 ? 0 : hours + 1
      },
      ArrowDown: hours => {
        return hours === 0 ? 23 : hours - 1
      }
    }

    return actionsByKey[key]
      ? actionsByKey[key](hours)
      : hours
  }

  const hoursToString = hours => {
    const hoursString = hours.toString()

    return hoursString.length === 1 ? '0' + hours : hours
  }

  const handleTimeSave = (e, isEndTime) => {
    const {
      originalstarttime,
      originalendtime,
      originaltime,
      originaleventcode,
      originalperiodcode
    } = e.currentTarget.dataset
    const time = e.currentTarget.innerText

    if (!isCustomTime(time)) {
      e.currentTarget.innerText = originaltime

      return
    }

    if (originaltime === time) {
      return
    }

    const startTime = isEndTime ? originalstarttime : time
    const endTime = isEndTime ? time : originalendtime

    const eventcodeType = eventCodes.find(e => props.t('event.code.' + e.type) === originaleventcode)

    saveCell(
      originalperiodcode,
      internship,
      shift,
      eventcodeType,
      getIsoTime(startTime),
      getIsoTime(endTime)
    )
  }

  const multiSelectionHandler = () => {
    props.multiSelectionAddingHandler([
      getMultiSelectionShiftEncapsulation(
        shift.id ? { ...shift } : makeNewShift(internship.id, getDefaultShiftDate(), getDefaultShiftDate()),
        props.index,
        props.columnIndex,
        internship
      )],
    SINGLE
    )
  }

  const getDefaultShiftDate = () => {
    return moment(props.currentDay.format(DATE_FORMAT_API))
  }

  /** ******************* Handler for PauseQuotaCell *********************/

  // @TODO 29-06-2022 : The other cells has to be adapt in order to use also these handlers
  const handlePauseShiftCellChange = (actualShift, key) => {
    if (key === 'periodCode') {
      handlePeriodCodeChange(actualShift)

      return
    }

    if (key === 'startTime' || key === 'endTime') {
      handleTimesChange(actualShift, key)

      return
    }

    if (key === 'eventCode') {
      handleEventCodeChange(actualShift)
    }
  }

  const handlePeriodCodeChange = actualShift => {
    if (actualShift.periodCode === shift.periodCode) {
      return
    }

    if (actualShift.periodCode === '') {
      removeShift(internship, shift)

      return
    }

    if (!isCorrectCode(actualShift.periodCode)) {
      setShiftErrorMessage('The preset code must be one of :')
      setShift({ ...shift })

      return
    }

    const newShift = { ...shift, periodCode: actualShift.periodCode }

    saveShift(newShift, internship)
  }

  const handleTimesChange = (actualShift, key) => {
    const time = objectTimeToStringTime(actualShift[key], true)
    const shiftTime = shift[key]

    if (shiftTime === time) {
      return
    }

    const newShift = { ...shift }
    newShift[key] = time

    saveShift(newShift, internship)
  }

  const handleEventCodeChange = actualShift => {
    if (isEmpty(actualShift.eventCodeType) && !shift.periodCode) {
      removeShift(actualShift)
      setShift({ ...shift, eventCodeType: actualShift.eventCodeType })

      return
    }

    if (isObject(actualShift.eventCodeType) && actualShift.eventCodeType.id === shift.eventCodeType.id) {
      return
    }

    if (typeof actualShift.eventCodeType === 'string') {
      const eventCodeType = eventCodes.find(e => props.t('event.code.' + e.type) === actualShift.eventCodeType.trim().toUpperCase())

      if (!eventCodeType) {
        setShiftErrorMessage(EVENT_CODE_ERROR_MESSAGE)
        setShift({ ...shift })

        return
      }

      actualShift.eventCodeType = eventCodeType
    } else if (actualShift.eventCode && !isCorrectEventCodeType(actualShift.eventCode.code)) {
      setShiftErrorMessage(EVENT_CODE_ERROR_MESSAGE)
      setShift({ ...shift })

      return
    }

    const newShift = { ...shift, eventCodeType: actualShift.eventCodeType }

    saveShift(newShift, internship)
  }

  const objectTimeToStringTime = (time, includeSeconds = false) => {
    const hours = timeToString(time.hours)
    const minutes = timeToString(time.minutes)
    const seconds = timeToString(time.seconds)

    return includeSeconds
      ? `${hours}:${minutes}:${seconds}`
      : `${hours}:${minutes}`
  }

  const timeToString = numberTime => {
    return numberTime >= 10 ? `${numberTime}` : `0${numberTime}`
  }

  const saveShift = (shift, internship) => {
    const backendPreset = getPresetFromShiftAndInternship(internship, shift)
    const newPreset = internship.presets.find(item => shift.periodCode === item.periodCode)

    /*
    * If the user is a school_user and the sector use quotaPerPause feature
    * Throw the modal in case of quota per pause exceeding
    * Send message into internship internal messagery
    */
    if (
      props.getUser.context === SCHOOL_CONTEXT &&
      (preset === null || preset.periodCode !== newPreset.periodCode) &&
      isObject(props.getSelectedSector) &&
      props.getSelectedSector.pausesEnabled
    ) {
      const remainingPlaces = computeCellRemainingPlace(
        props.getPauseQuotasCounts[props.columnIndex],
        props.getShiftsCounts[props.columnIndex],
        newPreset,
        true
      )

      if (remainingPlaces < 1) {
        dispatch({ type: SET_DISPLAY_EXCEEDING_MODAL, payload: true })
        sendInternshipMessage(props.getUser, { internship: internship.id, content: props.t(EXCEEDING_QUOTA_MESSAGE) + props.currentDay.format(EXCEEDING_QUOTA_DATE_FORMAT) })
      }
    }

    setShift({ ...shift })

    if (shift.id && shift.id > 0) {
      props.updateShiftFromPreset(backendPreset, props.getUser, props.columnIndex, newPreset, preset, shift)

      return
    }

    backendPreset.startDate = props.currentDay.format(DATE_FORMAT_API)
    props.addShiftFromPreset(backendPreset, props.getUser, props.columnIndex, newPreset)
  }

  const getPresetFromShiftAndInternship = (internship, shift) => {
    const body = {
      endTime: shift.endTime,
      eventCodeType: shift.eventCodeType,
      internship: internship.id,
      periodCode: shift.periodCode,
      startTime: shift.startTime
    }

    if (shift.id > 0) {
      body.shift = shift.id
    }

    return body
  }

  const getCellTreshold = () => {
    if (isObject(props.getSelectedSector) && props.getSelectedSector.id) {
      return computeCellTreshold(props.getPauseQuotasCounts[props.columnIndex], props.getShiftsCounts[props.columnIndex], preset, props.getSelectedSector.pausesEnabled)
    }

    return CELL_TRESHOLDS.UNFILLED
  }

  const renderComponent = () => {
    if (shift) {
      const Cell = isObject(props.getSelectedSector) && props.getSelectedSector.id ? PauseQuotaCell : FilledShiftCell

      return (
        <Cell
          internship={internship}
          shift={shift}
          preset={preset}
          errorMessage={shiftErrorMessage}
          startTime={formattedStartTime}
          endTime={formattedEndTime}
          displayEvent={displayEvent}
          color={shiftColor}
          eventCodeEditable={props.eventCodeEditable}
          index={props.index}
          columnIndex={props.columnIndex}
          multiSelectionMode={props.multiSelectionMode}
          contentEditable={props.contentEditable}
          eventCodeOnly={props.eventCodeOnly}
          isSelected={props.isSelected}
          inWidgetMode={props.inWidgetMode}
          onSave={handleSave}
          onSaveEventCode={handleSaveEventCode}
          onTimeSave={handleTimeSave}
          onTimeChange={handleTimeChange}
          setDisplayEvent={setDisplayEvent}
          multiSelectionHandler={multiSelectionHandler}
          onDesiderataSelect={props.onDesiderataSelect}
          onCellChange={handlePauseShiftCellChange}
          resetErrorMessage={() => setShiftErrorMessage(null)}
          cellTreshold={getCellTreshold()}
        />
      )
    }

    return (
      <div />
    )
  }

  return (
    <td
      style={{
        width: '83px'
      }}
      key={internship.id + '_' + props.columnIndex}
      className={className}
    >
      {renderComponent()}
    </td>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftCell)
