import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLORS_PALETTE } from '../../config/colors'

export const FONT_SIZES = {
  SMALL: 'small',
  REGULAR: 'regular',
  LARGE: 'large',
  LARGER: 'larger'
}
const ICON_SIZE_DEFAULT = { height: '20px', width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }

const IconLabel = ({ fontSize, icon, padding = 'pdl-8', text, color = COLORS_PALETTE.SECOND }) => {
  const fontSizeClass = useMemo(() => {
    let size = 'ft-reg'

    if (fontSize === 'small') {
      size = 'ft-sm'
    } else if (fontSize === 'large') {
      size = 'ft-l'
    } else if (fontSize === 'larger') {
      size = 'ft-lg'
    }

    return size
  }, [fontSize])

  return (
    <div className={`flex-row ${padding}`}>
      <div style={ICON_SIZE_DEFAULT}> <FontAwesomeIcon icon={icon} color={color} /> </div>
      <span className={`${fontSizeClass} ml-8`}> {text} </span>
    </div>
  )
}

export default IconLabel
