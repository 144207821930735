import React, { useState, useEffect, useContext } from 'react'

import InformationDisplay from './InformationDisplay'
import LoadingSkeleton from './LoadingSkeleton'
import moment from 'moment'
import { Drawer, Button, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleArrows, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { mapStateToProps, mapDispatchToProps, connect } from '../../reducers/Dispatchers'
import { request, generalErrorHandler, requestWithPromise } from '../../utils'
import InternshipValidationButton from './ShiftsManager/InternshipValidationButton'
import { SCHOOL_CONTEXT, internshipStates } from '../../utils/constants'
import InternshipValidation from './ShiftsManager/InternshipValidation'
import ConventionHandler from './ConventionHandler'
import ScheduleBonusTable from './ScheduleBonusTable'
import History from './History'
import { Shift } from '../../utils/entities/shift'
import { getTimezoneLessMoment } from '../../utils/momentjs'
import { getInternshipShifts } from '../../utils/api/internship'
import { getIconByName } from '../../utils/fontAwesomeHelper'

import '../../assets/shifts-manager.scss'
import { DEFAULT_STATE, InternshipDocumentsDrawerContext } from '../../Providers/Drawer/InternshipDocumentsDrawer'

const InternshipInfo = (props) => {
  const { setSelectedInternship } = useContext(InternshipDocumentsDrawerContext)

  const [internship, setInternship] = useState(null)
  const [shifts, setShifts] = useState([])
  const [openValidationDrawer, setOpenValidationDrawer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingContactPersons, setLoadingContactPersons] = useState(true)
  const [contactPersons, setContactPersons] = useState([])
  const [contactPersonsSector, setContactPersonsSector] = useState([])
  const [contactPersonsSection, setContactPersonsSection] = useState([])
  const [scheduleBonusModalVisible, setScheduleBonusModalVisible] = useState(false)

  useEffect(() => {
    if (props.internship !== undefined) {
      if (props.internship) {
        fetchInternshipData(props.internship.id, props.getUser)
      }
      savePropsToState(props)
    }
  }, [props.internship, props.getUser])

  const fetchInternshipData = (internshipId, user) => {
    getInternshipShifts({ id: internshipId }, user).then(json => {
      if (json?.data) {
        setShifts(json.data)
      }
    })
    props.fetchInternshipDocuments(internshipId, user)
  }

  const savePropsToState = async props => {
    if (props.internship === null) {
      setInternship(null)
      setLoadingContactPersons(true)
      setContactPersons([])

      return
    }

    request('/internship/details/' + props.internship.id, 'GET', null, props.getUser)
      .then(json => {
        setInternship(json.data)
        setLoading(false)
        loadContactPersons(json.data)
      })
      .catch(error => { generalErrorHandler(error) })
  }

  const handleInternshipValidation = () => {
    const currentInternship = { ...internship }

    currentInternship.state = internshipStates.SCHEDULE_VALIDATED

    setInternship(currentInternship)

    if (props.onInternshipValidate) {
      props.onInternshipValidate(currentInternship)
    }
  }

  const handleInternshipInvalidation = newState => {
    const currentInternship = { ...internship }

    currentInternship.state = newState

    setInternship(currentInternship)

    if (props.onInternshipValidate) {
      props.onInternshipValidate(currentInternship)
    }
  }

  const renderSchedule = internship => {
    const sortedShifts = shifts.sort((a, b) => {
      return moment(a.startDate).diff(moment(b.startDate))
    })

    return (
      <History
        data={sortedShifts.map(s => {
          const shift = new Shift(s)
          const pauseIcon = shift.getPauseIcon()

          return {
            date: shift.startDate,
            body: shift.isAbsent()
              ? <span className='absent-schedule'><b> {props.t('Absent')} </b></span>
              : <span className='default-schedule'> {shift.getSchedule()} {pauseIcon && <FontAwesomeIcon icon={getIconByName(pauseIcon)} />} </span>
          }
        })}
        period={{
          startDate: getTimezoneLessMoment(internship.startDate),
          endDate: getTimezoneLessMoment(internship.endDate)
        }}
        strictMode
      />
    )
  }

  const loadContactPersons = async (internship) => {
    const { getUser } = props
    const { institution, school, sector, section } = internship

    if (institution && sector && section && school) {
      try {
        const contactPersonsUrl = institution.id !== undefined ? `/contact-persons/${institution.id}/${sector.id}/${school.id}/${section.id}` : `/contact-persons/${institution}/${sector}/${school}/${section}`

        const { data } = await requestWithPromise(contactPersonsUrl, 'GET', null, getUser)
        const dataSector = await requestWithPromise(`/api/sectors/${sector.id}/contact-persons`, 'GET', null, getUser)
        const dataSection = await requestWithPromise(`/api/school-sections/${section.id}/contact-persons`, 'GET', null, getUser)

        if (!data.status || data.status !== 'error') {
          setContactPersons(data)
          setContactPersonsSector(dataSector.data)
          setContactPersonsSection(dataSection.data)
        } else {
          setContactPersons([])
          setContactPersonsSector([])
          setContactPersonsSection([])
        }
      } catch (err) {
        generalErrorHandler(err)
      }
    }

    setLoadingContactPersons(false)
  }

  const renderContactPersons = () => {
    const { t } = props

    if (loadingContactPersons) {
      return (
        <div className='flex-column'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className='loading-icon black' />
            <div style={{ marginLeft: '4px', marginTop: '4px' }}>{t('Loading contact persons')}</div>
          </div>
        </div>
      )
    }

    if (!contactPersons.length && !contactPersonsSector.length && !contactPersonsSection.length) {
      return (
        <div className='flex-column'>
          {t('None')}
        </div>
      )
    }

    const schoolContactPersons = contactPersons.filter(item => item.type === 'school').concat(contactPersonsSection)
    const institutionContactPersons = contactPersons.filter(item => item.type === 'institution').concat(contactPersonsSector)

    return (
      <div className='flex-column'>
        {schoolContactPersons.length > 0 && schoolContactPersons.map(schoolContactPerson => <div key={schoolContactPerson.email}><b>{t('School')}:</b>&nbsp;{`${schoolContactPerson.lastname} ${schoolContactPerson.firstname} - `}<a href={'mailto:' + schoolContactPerson.email}>{schoolContactPerson.email}</a>{schoolContactPerson.phone !== null && schoolContactPerson.phone !== '' ? ` - ${schoolContactPerson.phone}` : ''}</div>)}
        {institutionContactPersons.length > 0 && institutionContactPersons.map(institutionContactPerson => <div key={institutionContactPerson.email}><b>{t('Institution')}:</b>&nbsp;{`${institutionContactPerson.lastname} ${institutionContactPerson.firstname} - `}<a href={'mailto:' + institutionContactPerson.email}>{institutionContactPerson.email}</a>{institutionContactPerson.phone !== null && institutionContactPerson.phone !== '' ? ` - ${institutionContactPerson.phone}` : ''}</div>)}
      </div>
    )
  }

  const renderScheduleTitle = () => {
    const title = props.t('Schedule')

    return (
      <div className='schedule-title full-width flex-row'>
        <b> {title} </b>
        {props.getUser.context.includes(SCHOOL_CONTEXT) && (
          <Button
            type='default'
            size='small'
            onClick={() => setScheduleBonusModalVisible(true)}
          >
            <FontAwesomeIcon icon={faPlusCircle} color='blue' />
            <span> {props.t('Hour bonus')} </span>
          </Button>
        )}
      </div>
    )
  }

  const renderBody = () => {
    let title = ''
    let content = <LoadingSkeleton />

    if (internship !== null) {
      let validateButtonTooltip = props.t('Validate internship')

      switch (internship.state) {
        case internshipStates.SCHEDULE_TO_VALIDATE:
          validateButtonTooltip = props.t('Internship to validate, student uploaded signed document')
          break
        case internshipStates.SCHEDULE_VALIDATED:
          validateButtonTooltip = props.t('Internship validated')
          break
      }

      title = (
        <div className='internship-sheet-title'>
          <span>
            {props.t('Internship sheet')}
            {loading || props.loading
              ? (
                <div className='loading-icon black' style={{ marginTop: '1px' }} />
              )
              : null}
          </span>
          <span className='internship-validation-button-custom'>
            <InternshipValidationButton
              validateButtonTooltip={validateButtonTooltip}
              onDisplayValidationDrawer={() => setOpenValidationDrawer(true)}
              internship={internship}
            />
          </span>
        </div>
      )

      const internshipInfo = [
        {
          id: 9,
          icon: 'user',
          title: props.t('Student'),
          caption: internship.student ? internship.student.firstname + ' ' + internship.student.lastname : props.t('Without student')
        },
        {
          id: 1,
          icon: 'clinic-medical',
          title: props.t('Institution'),
          caption:
            internship.institution === null
              ? props.t('Without institution')
              : internship.institution.name
        },
        {
          id: 2,
          icon: 'stethoscope',
          title: props.t('Care unit'),
          caption:
            internship.sector === null
              ? props.t('Without care unit')
              : internship.sector.name
        },
        {
          id: 3,
          icon: faPeopleArrows,
          title: props.t('Contact persons'),
          caption: renderContactPersons()
        },
        {
          id: 4,
          icon: 'cog',
          title: props.t('Internship year'),
          caption:
            internship.internshipYear === null
              ? props.t('Without internship year')
              : internship.internshipYear.schoolYearName ?? internship.internshipYear.name
        },
        {
          id: 5,
          icon: 'calendar-alt',
          title: props.t('Dates'),
          caption:
            props.t('From') +
            ' ' +
            moment(internship.startDate).format('DD MMMM YYYY') +
            ' ' +
            props.t('to') +
            ' ' +
            moment(internship.endDate).format('DD MMMM YYYY')
        },
        {
          id: 6,
          icon: 'file',
          title: props.t('Internship convention'),
          caption: <ConventionHandler internship={internship} />
        },
        {
          id: 7,
          icon: 'file',
          title: props.t('Other internship documents'),
          caption: (
            <div>
              <Button
                type='default'
                size='small'
                onClick={() => setSelectedInternship({ ...DEFAULT_STATE, data: props.internship })}
              >
                <FontAwesomeIcon icon='paperclip' />
                &nbsp;{props.t('Attached file(s)')}
              </Button>
            </div>
          )
        },
        {
          id: 8,
          icon: 'calendar-alt',
          title: renderScheduleTitle(),
          caption: renderSchedule(internship)
        }
      ]

      content = (
        <div className='flex-column'>
          <InformationDisplay information={internshipInfo} />
        </div>
      )
    }

    return (
      <Drawer
        title={title}
        width='550px'
        onClose={props.onClose}
        visible={props.internship !== null}
      >
        {content}
        {openValidationDrawer &&
          <InternshipValidation
            internship={internship}
            onClose={() => setOpenValidationDrawer(false)}
            onValidate={handleInternshipValidation}
            onInvalidate={handleInternshipInvalidation}
          />}
        {props.getUser.context.includes(SCHOOL_CONTEXT) && (
          <Modal
            title={props.t('Hour bonus')}
            visible={scheduleBonusModalVisible}
            onCancel={() => setScheduleBonusModalVisible(false)}
            footer={null}
            destroyOnClose
            width={750}
          >
            <ScheduleBonusTable internship={internship} />
          </Modal>
        )}
      </Drawer>
    )
  }

  return (renderBody())
}

export default connect(mapStateToProps, mapDispatchToProps)(InternshipInfo)
