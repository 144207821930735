import React, { useMemo, useState } from 'react'

import useRequest from '../../../../hooks/useRequest'
import { TYPES } from '../../../../utils/entities/internshipDocument'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { connect } from 'react-redux'
import InstitutionDocumentsTable from './InstitutionDocumentsTable'
import { deleteInstitutionDocument, getInstitutionDocuments } from '../../../../utils/api/institution'

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const InstitutionDocumentsManager = ({ user, institution, readOnly, onClose, onFileDelete, t }) => {
  const [parameters, setParameters] = useState({})
  const { data, loading, reload } = useRequest({ request: getInstitutionDocuments, parameters: useMemo(() => [user, institution], [user?.id, institution]) })

  const displayedFiles = useMemo(() => data.filter(f => f.type !== TYPES.CONVENTION), [data])

  const handleDelete = file => {
    if (!readOnly) {
      deleteInstitutionDocument(user, file).then(reload)
    }
  }

  return (
    <InstitutionDocumentsTable
      data={displayedFiles}
      readOnly={readOnly}
      institution={institution}
      loading={loading}
      parameters={parameters}
      onDelete={handleDelete}
      onParametersChange={setParameters}
      reload={reload}
    />
  )
}

export default connect(mapStateToProps)(InstitutionDocumentsManager)
