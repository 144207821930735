import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import SmartTable, { DATA_TYPE_BOOLEAN_TAG, DATA_TYPE_DATE, DATA_TYPE_ID, DATA_TYPE_STRING } from '../../Components/shared/SmartTable'
import StudentInfo from '../../Components/shared/StudentInfo'
import AdminResetPassword from '../../Components/shared/AdminResetPassword'
import PublipostageDrawer from '../../Components/institution/PublipostageDrawer'
import { INSTITUTION_OPTION_PUBLIPOSTAGE, ROLE_ADMIN, ROLE_HOSPITAL_ADMIN } from '../../utils/constants'
import Analytics from '../../utils/analytics'
import { getUser } from '../../reducers/UserReducer'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { faKey, faUserGraduate } from '@fortawesome/free-solid-svg-icons'
import { faFileArchive } from '@fortawesome/free-regular-svg-icons'
import { getInstitutions } from '../../reducers/InstitutionsReducer'
import { isArray } from 'lodash'
import { fetchInstitutionTemplates } from '../../reducers/InstitutionTemplatesReducer/InstitutionTemplatesReducer'
import { InstitutionContext } from '../../Providers/InstitutionProvider'
import { bindActionCreators } from 'redux'

const STUDENT_INFO = 'STUDENT_INFO'
const RESET_PASSWORD = 'RESET_PASSWORD'
const PUBLIPOSTAGE = 'PUBLIPOSTAGE'

const mapStateToProps = state => {
  return {
    institution: getInstitutions(state)[0],
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchInstitutionTemplates: bindActionCreators(fetchInstitutionTemplates, dispatch)
  }
}

const StudentsView = ({ institution, user, fetchInstitutionTemplates, t }) => {
  const { institutionStudents } = useContext(InstitutionContext)

  const [selectedStudent, setSelectedStudent] = useState({ data: null, type: null })

  const usePublipostage = useMemo(() => {
    let use = false

    if (institution && isArray(institution.institutionOptions)) {
      institution.institutionOptions.forEach(option => {
        if (option.optionType === INSTITUTION_OPTION_PUBLIPOSTAGE) {
          use = true
        }
      })
    }

    return use
  }, [institution])

  useEffect(() => Analytics.pageView('/institution-students'), [])
  useEffect(() => {
    if (usePublipostage && institution) {
      fetchInstitutionTemplates(institution.id, user)
    }
  }, [usePublipostage, user?.id, institution?.id, fetchInstitutionTemplates])

  const canResetPassword = useMemo(() => [ROLE_HOSPITAL_ADMIN, ROLE_ADMIN].includes(user.roles[0]), [user.roles])
  const showNiss = useMemo(() => [ROLE_HOSPITAL_ADMIN].includes(user.roles[0]), [user.roles])
  const columns = useMemo(() => {
    let columns = [
      { type: DATA_TYPE_ID, key: 'id' }
    ]

    if (!user.hideStudentSectionForHospitalUser) {
      columns.push({ type: DATA_TYPE_STRING, name: t('Section'), key: 'section' })
    }

    columns = [
      ...columns,
      { type: DATA_TYPE_STRING, name: t('Lastname'), key: 'lastname' },
      { type: DATA_TYPE_STRING, name: t('Firstname'), key: 'firstname' },
      { type: DATA_TYPE_STRING, name: t('Email'), key: 'email' },
      { type: DATA_TYPE_STRING, name: t('Registration number'), key: 'matricule' },
      { type: DATA_TYPE_STRING, name: t('Phone'), key: 'phoneNumber' },
      { type: DATA_TYPE_DATE, name: t('Birthdate'), key: 'birthdate', format: 'DD/MM/YYYY' },
      { type: DATA_TYPE_STRING, name: t('Gender'), key: 'gender' },
      { type: DATA_TYPE_STRING, name: t('Nationality'), key: 'nationality' },
      {
        type: DATA_TYPE_BOOLEAN_TAG,
        name: t('Data use'),
        key: 'dataUsage',
        yesMessage: 'Accepts the use of their data for recruitment purposes',
        noMessage: 'Does not accept the use of their data for recruitment purposes'
      }
    ]

    if (showNiss) {
      columns.push({ type: DATA_TYPE_STRING, name: t('NISS'), key: 'niss', password: true })
    }

    return columns
  }, [user.hideStudentSectionForHospitalUser, showNiss, t])
  const additionalActions = useMemo(() => {
    const additionalActions = [
      {
        key: 'student-info',
        iconName: faUserGraduate,
        type: 'primary',
        title: t('Student sheet'),
        onClick: s => setSelectedStudent({ data: s, type: STUDENT_INFO })
      }
    ]

    if (usePublipostage) {
      additionalActions.push({
        key: 'publipostage',
        iconName: faFileArchive,
        type: 'primary',
        title: t('Publipostage templates list'),
        onClick: s => setSelectedStudent({ data: s, type: PUBLIPOSTAGE })
      })
    }

    if (canResetPassword) {
      additionalActions.push({
        key: 'reset-password',
        iconName: faKey,
        type: 'primary',
        title: t('Reset password'),
        onClick: s => setSelectedStudent({ data: s, type: RESET_PASSWORD }),
        disabledCallback: s => s.user?.isExternal,
        titleCallback: s => s.user?.isExternal ? t('This user is external and cannot have its password reset') : ''
      })
    }

    return additionalActions
  }, [usePublipostage, canResetPassword, t, setSelectedStudent])

  const resetSelectedStudent = useCallback(() => setSelectedStudent({ data: null, type: null }, [setSelectedStudent]))
  const localStudents = useMemo(() => institutionStudents.data.map(s => {
    let section = null

    if (s.sections.length > 0) {
      if (s.sections[0].sectionToYear) {
        section = s.sections[0].sectionToYear.sectionAbbreviation
      } else if (s.sections[0].abbreviation) {
        section = s.sections[0].abbreviation
      } else if (s.sections[0].comment) {
        section = s.sections[0].comment
      }
    }

    return { ...s, section }
  }), [institutionStudents.data])

  return (
    <div>
      <SmartTable
        columns={columns}
        data={localStudents}
        loading={institutionStudents.loading}
        noDataText={t(
          'Your institution currently has no student.'
        )}
        onDataDelete={undefined}
        noDataDelete
        additionalActions={additionalActions}
      />
      <StudentInfo
        student={selectedStudent.type === STUDENT_INFO ? selectedStudent.data : null}
        onClose={resetSelectedStudent}
      />
      {canResetPassword && (
        <AdminResetPassword
          userToResetPassword={selectedStudent.type === RESET_PASSWORD ? selectedStudent.data : null}
          onClose={resetSelectedStudent}
        />
      )}
      {usePublipostage && (
        <PublipostageDrawer
          student={selectedStudent.type === PUBLIPOSTAGE ? selectedStudent.data : null}
          onClose={resetSelectedStudent}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentsView)
