import React, { useState, useContext, useEffect, useMemo } from 'react'

import { Drawer, notification } from 'antd'
import { requestWithPromise, generalErrorHandler, recursiveIncludes, isObject } from '../../utils'
import UserSectorTable, { DATA_TYPE_SELECT, DATA_TYPE_CHECK_LOCAL_ADMINISTRATOR, DATA_TYPE_CHECK_CONTACT_PERSON, DATA_TYPE_TAG } from './UserSectorTable'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { ADMIN_CONTEXT, ORDER_BY, ROLE_HOSPITAL_ADMIN, ROLE_INSTITUTION_GROUP_ADMIN } from '../../utils/constants'
import { getUser, setUserManagedSectors } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { onError } from '../../utils/apiHelper'
import { bindActionCreators } from 'redux'
import Loading from '../../HOC/Loading'
import { getInstitutionSectors } from '../../utils/api/institution'
import { getUserSectors } from '../../utils/api/user'
import { InstitutionContext } from '../../Providers/InstitutionProvider'
import { isHospitalAdmin } from '../../utils/roles'

const mapStateToProps = state => ({ user: getUser(state.getUser), t: getTranslate(state.locale) })

const mapDispatchToProps = dispatch => ({ setReduxUserManagedSectors: bindActionCreators(setUserManagedSectors, dispatch) })

const UserManagedSectors = ({ user, schoolId, selectedUser, onClose, setReduxUserManagedSectors, t }) => {
  const { sectors, archivedSectors } = useContext(InstitutionContext)
  const [userAffiliatedSectors, setUserAffiliatedSectors] = useState([])
  const [userSectors, setUserSectors] = useState({ contactPersons: [], affiliated: [] })

  const [loading, setLoading] = useState(false)

  const isSuperAdmin = useMemo(() => user.context === ADMIN_CONTEXT, [user])
  const affiliatedSectors = useMemo(() => {
    return userAffiliatedSectors.map(s => {
      return {
        id: s.id,
        sector: s.id,
        name: s.name,
        archived: s.archived
      }
    }).filter(s => !s.archived || archivedSectors.some(si => si.id === s.id))
  }, [userAffiliatedSectors])

  useEffect(() => {
    if (selectedUser !== null) {
      const synchroTools = { function: getUserSectors, parameters: [user, selectedUser] }

      if (recursiveIncludes(selectedUser.roles, [ROLE_HOSPITAL_ADMIN, ROLE_INSTITUTION_GROUP_ADMIN])) {
        synchroTools.function = getInstitutionSectors
        synchroTools.parameters = [user, selectedUser.institutions[0], { archived: null, orderBy: ORDER_BY.NAME, strictMode: 0 }]
      }

      setLoading(true)

      synchroTools.function(...synchroTools.parameters).then(json => {
        if (json?.data) {
          loadUser(json.data)
        }

        setLoading(false)
      })
    } else {
      setUserAffiliatedSectors([])
    }
  }, [selectedUser, user])

  const loadUser = sectors => {
    const userSectors = {
      contactPersons: [],
      affiliated: []
    }

    sectors.forEach(s => {
      userSectors.contactPersons[s.id] = s.contactPersons.map(contactPerson => {
        return contactPerson.id
      })
      userSectors.affiliated[s.id] = s.affiliates.map(user => {
        return user.id
      })
    })

    setUserSectors(userSectors)
    setUserAffiliatedSectors(sectors)
  }

  const handleSectorAddition = sector => {
    if (sector.newId === null) {
      return
    }

    const newSector = sectors.find(s => s.id === sector.newId)

    let affiliatedSectors = userAffiliatedSectors
    const userManagedSectorIds = affiliatedSectors.map(s => s.id)

    if (userManagedSectorIds.includes(newSector.id)) {
      onError(t('This user already coordinates this sector'))
      return
    } else {
      affiliatedSectors = userAffiliatedSectors.concat(newSector)
    }

    handleUserCoordinatedSectorUpdating(affiliatedSectors)
  }

  const handleSectorEdition = sector => {
    const editedSector = sectors.find(s => s.id === sector.newId)
    const userManagedSectorIds = affiliatedSectors.map(s => s.id)

    if (userManagedSectorIds.includes(editedSector.id)) {
      onError(t('This user already coordinates this sector'))

      return
    }

    handleUserCoordinatedSectorUpdating(userAffiliatedSectors.map(ums => ums.id === sector.prevId ? editedSector : ums))
  }

  const handleSectorDeletion = sector => {
    const affiliatedSectors = userAffiliatedSectors.filter(ums => ums.id !== sector.sector)

    handleUserCoordinatedSectorUpdating(affiliatedSectors)
  }

  const handleUserCoordinatedSectorUpdating = (userAffiliatedSectors, confirmationMessage = false) => {
    if (selectedUser.id === user.id) {
      setReduxUserManagedSectors(userAffiliatedSectors)
    }

    setUserAffiliatedSectors(userAffiliatedSectors)

    const body = { affiliatedSectors: userAffiliatedSectors.map(ums => ums.id) }

    if (isSuperAdmin && schoolId) {
      body.schoolId = schoolId
    }

    requestWithPromise('/user/modify/' + selectedUser.id, 'POST', body, user)
      .then((json) => {
        if (confirmationMessage) {
          notification.success({ message: t('Saved changes'), placement: 'bottomRight' })
        }
      })
      .catch((error) => { generalErrorHandler(error) })
  }

  const userIsContactPerson = sectorId => {
    if (selectedUser && userSectors.contactPersons[sectorId]) {
      return userSectors.contactPersons[sectorId].includes(selectedUser.id)
    }
  }

  const userIsAdmin = sectorId => {
    if (selectedUser && userSectors.affiliated[sectorId]) {
      return userSectors.affiliated[sectorId].includes(selectedUser.id)
    }
  }

  const setUserContactPerson = sectorId => {
    const body = {
      userToAdd: selectedUser.id
    }

    requestWithPromise(`/api/sectors/${sectorId}/contact-persons`, 'POST', body, user)
      .then(() => {
        notification.success({ message: t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const setManagedUser = sectorId => {
    const body = {
      userToAdd: selectedUser.id
    }

    requestWithPromise(`/api/sectors/${sectorId}/managed-user`, 'POST', body, user)
      .then(() => {
        notification.success({ message: t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const setMultipleUserContactPersons = sectors => {
    const body = {
      userToAdd: selectedUser.id,
      sectors: sectors
    }

    requestWithPromise('/sectors/contact-persons', 'POST', body, user)
      .then(() => {
        notification.success({ message: t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const setMultipleManagedUsers = sectors => {
    const body = {
      userToAdd: selectedUser.id,
      sectors: sectors
    }

    requestWithPromise('/sectors/managed-users', 'POST', body, user)
      .then(() => {
        notification.success({ message: t('Saved changes'), placement: 'bottomLeft' })
      })
  }

  const searchWithFilter = (value, isAdmin) => {
    const search = typeof value !== 'undefined' ? `?search=${value}` : ''

    if (isAdmin) {
      requestWithPromise(`/api/institutions/${selectedUser.institutions[0].id}/sectors${search}`, 'GET', null, user)
        .then(jsonResponse => {
          if (jsonResponse && jsonResponse.data) {
            loadUser(jsonResponse.data)
          }
        })
    } else {
      requestWithPromise(`/api/users/${selectedUser.id}/sectors${search}`, 'GET', null, user)
        .then(jsonResponse => {
          if (jsonResponse && jsonResponse.data) {
            loadUser(jsonResponse.data)
          }
        })
    }
  }

  const columns = useMemo(() => {
    const columns = [{ type: DATA_TYPE_SELECT, name: t('Sector'), options: sectors }]

    if (isHospitalAdmin(selectedUser)) {
      columns.push({ type: DATA_TYPE_CHECK_LOCAL_ADMINISTRATOR, name: t('Local administrator') })
    }

    columns.push({ type: DATA_TYPE_CHECK_CONTACT_PERSON, name: t('Contact persons') })
    columns.push({ type: DATA_TYPE_TAG, name: t('Status') })

    return columns
  }, [t, sectors, selectedUser])

  const additionalActions = [
    {
      icon: faTrash,
      type: 'danger',
      title: 'Delete',
      titlePopconfirm: t('Delete this sector?'),
      handleOnClick: data => handleSectorDeletion(data)
    }
  ]

  return (
    <Drawer
      title={isObject(selectedUser) ? t('Sectors managed by') + ' ' + selectedUser.email : '?'}
      width='640px'
      onClose={onClose}
      visible={selectedUser !== null}
    >
      <Loading loading={loading}>
        <UserSectorTable
          onDataAdd={handleSectorAddition}
          onDataEdit={handleSectorEdition}
          data={affiliatedSectors}
          columns={columns}
          additionalActions={additionalActions}
          userIsContactPerson={userIsContactPerson}
          userIsAdmin={userIsAdmin}
          setUserContactPerson={setUserContactPerson}
          setManagedUser={setManagedUser}
          setMultipleUserContactPersons={setMultipleUserContactPersons}
          setMultipleManagedUsers={setMultipleManagedUsers}
          user={selectedUser}
          searchWithFilter={searchWithFilter}
        />
      </Loading>
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagedSectors)
