import React, { useState, useEffect, useRef } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import Cookie from 'js-cookie'
import { Button, Select, notification } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import { requestWithPromise, getContextFromRoles } from '../../utils'
import { getRoles, getUsers } from '../../utils/api/user'
import routes from '../../routes'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import '../../assets/switch-user.scss'
import { URIEncode } from '../../utils/apiHelper'

const Option = Select.Option
const TIME_BEFORE_SEARCH = 300

const UserSwitch = props => {
  const [users, setUsers] = useState([])
  const [roles, setRoles] = useState([])
  const [filter, setFilter] = useState('')
  const [selectedRole, setSelectedRole] = useState(null)
  const [loading, setLoading] = useState(false)

  let timeoutFilterSearchId
  const differateSearchIdRef = useRef(timeoutFilterSearchId)

  useEffect(() => {
    // if user is not admin, navigate to /internships
    verifyUserIsAdmin()
    if (
      typeof Cookie.get('switch_user_id') !== 'undefined' &&
      Cookie.get('switch_user_id') !== 'undefined'
    ) {
      requestWithPromise(
        '/api/User/' + Cookie.get('switch_user_id'),
        'GET',
        null,
        props.getUser
      ).then(json => {
        props.switchUser(json.data)
      })
    }
  }, [])

  useEffect(() => {
    if (props.getUser) {
      getRoles(props.getUser).then(json => {
        if (json && json.data) {
          setRoles(json.data)
        }
      })
    }
  }, [typeof props.getUser.id === 'number'])

  useEffect(() => {
    if (typeof filter === 'string') {
      differateSearch(filter, selectedRole)
    }
  }, [filter, selectedRole])

  const differateSearch = (newFilter, selectedRole) => {
    if (typeof differateSearchIdRef.current === 'number') {
      clearTimeout(differateSearchIdRef.current)
    }

    differateSearchIdRef.current = setTimeout(() => {
      fetchUsers(newFilter, selectedRole)
    }, TIME_BEFORE_SEARCH)
  }

  const verifyUserIsAdmin = () => {
    const {
      getUser: { roles }
    } = props
    if (getContextFromRoles(roles) !== 'ADMIN') {
      if (roles.length === 1 && ['ROLE_NURSE', 'ROLE_VALIDATOR'].includes(roles[0])) {
        props.history.push(routes.SHIFTS_MANAGER)
      } else {
        props.history.push(routes.INTERNSHIPS)
      }
    }
  }

  const fetchUsers = (newFilter, selectedRole) => {
    if (newFilter.length > 2) {
      setLoading(true)
      getUsers(URIEncode(newFilter), props.getUser, 0, selectedRole)
        .then(json => {
          if (json && json.data) {
            setUsers(json.data)
          }

          setLoading(false)
        }).catch(err => {
          setLoading(false)
          notification.error({
            message: err,
            placement: 'bottomRight'
          })
        })
    } else {
      setUsers([])
    }
  }

  const renderUserOptions = () => {
    if (users.length) {
      return users.map(u => {
        return (
          <Option key={u.id} value={u.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <b>{u.username + ' '}</b><span>[{u.roles}]</span>
          </Option>
        )
      })
    }
  }

  const switchUser = id => {
    if (id === 'default') return

    const user = users.filter(u => u.id === id)[0]
    Cookie.set('switch_user_id', JSON.stringify(user.id), { expires: 7 })
    props.switchUser(user)
  }

  const switchUserLogout = () => {
    Cookie.remove('switch_user_id')
    props.onLogout()
  }

  const renderSuffixIcon = () => {
    return (
      <div className='switch-user-loading'>
        {props.t('misc.loading')}
        <FontAwesomeIcon icon={faCog} spin />
      </div>
    )
  }

  const render = () => {
    return (
      <div className='switch-user-container flex-column' onKeyUp={e => setFilter(e.target.value)}>
        <h2>{props.t('Who do you want to impersonate ?')}</h2>
        <div className='select-container'>
          <b> {props.t('Role:')} </b>
          <Select
            style={{ width: '100%' }}
            showSearch
            defaultValue={null}
            onChange={setSelectedRole}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={() => true}
            suffixIcon={renderSuffixIcon()}
          >
            <Option key='all' value={null}>
              *
            </Option>
            {roles.map(r => {
              return (
                <Option key={r} value={r}>
                  {r}
                </Option>
              )
            })}
          </Select>
        </div>
        <div className='select-container'>
          <b> {props.t('Identifier:')} </b>
          <Select
            style={{ width: '100%' }}
            showSearch
            onChange={switchUser}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={() => true}
            loading={loading}
            suffixIcon={renderSuffixIcon()}
          >
            {renderUserOptions()}
          </Select>
        </div>
        <div className='v-spacing' />
        <div className='flex-row'>
          <div className='flex-fill' />
          <Button onClick={switchUserLogout}>
            <FontAwesomeIcon icon='sign-out-alt' />
            &nbsp;{props.t('Log out')}
          </Button>
        </div>
      </div>
    )
  }

  return render()
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserSwitch)
