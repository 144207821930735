import React, { useState, useEffect } from 'react'

import { Button, Tooltip, Popconfirm } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'

/**
 * A standart factory to build a Button Component
 * Needed props :
 *  {function} callBack the callback call when the button is clicked
 *  {*}        dataId   the id of the data in a dataSet
 *
 * Optionnal props :
 *  {boolean} addPopconfirm   set to true in order to add pop-up when button is clicked in order to confirm
 *                              usage of button callback
 *  {string}  iconName        the name off the icon in the button. Can be found in FONTAWESOME LIBRARY
 *  {string}  title           text in tooltip pop-up
 *  {string}  type            type of button. can be found in ANTD LIBRARY
 *  {boolean} disable         set to true in order to disable the button
 *  {string}  className       string containing one or more css class used to modify button visual aspect
 *  {integer} mouseLeaveDelay time before the tooltip is closed
 *
 * @param {*} props
 * @returns A fully configured Button Component that can be directly used
 */
const OptionButton = props => {
  /**
   * Callback function when the button is clicked
   */
  const onclick = () => {
    props.callBack ? props.callBack(props.dataId) : console.log('No callback for this button')
  }

  /**
   * Render the Button Component and his Tooltip
   *
   * @returns a grappe react containing a antd Button optionnaly wrap in a antd Tooltip Component
   */
  const renderButton = () => {
    if (props.addPopconfirm) {
      return (
        <Popconfirm
          placement='top'
          okType='danger'
          title={props.title}
          okText={props.t('Yes')}
          cancelText={props.t('Cancel')}
          key={props.dataId + props.iconName}
          onConfirm={onclick}
        >
          <Button
            className={props.className}
            type={props.type ? props.type : 'primary'}
            size='small'
            style={{ width: '28px', marginLeft: '2px' }}
            disabled={props.disabled ? props.disabled : false}
          >
            <FontAwesomeIcon icon={props.iconName} />
          </Button>
        </Popconfirm>
      )
    } else {
      return (
        <Button
          className={props.className}
          type={props.type ? props.type : 'primary'}
          size='small'
          onClick={onclick}
          style={{ width: '28px', marginLeft: '2px' }}
          disabled={props.disabled ? props.disabled : false}
          key={props.dataId + props.iconName}
        >
          <FontAwesomeIcon icon={props.iconName} />
        </Button>
      )
    }
  }

  /**
   * Render this Component body
   *
   * @returns a grappe react containing a antd Button wrap in a antd Tooltip component
   */
  const renderAll = () => {
    if (props.addTooltip) {
      return (
        <Tooltip
          placement='top'
          title={props.title}
          key={props.dataId + props.iconName}
          mouseLeaveDelay={props.mouseLeaveDelay ? props.mouseLeaveDelay : 0.1}
        >
          {renderButton()}
        </Tooltip>
      )
    } else {
      return renderButton()
    }
  }

  // Component return
  return renderAll()
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionButton)
